import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Button, Box, Breadcrumbs, Typography, Stack, IconButton, Alert, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LineChart } from '@mui/x-charts';
// import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
// import { DateField } from '@mui/x-date-pickers/DateField';
import TextField from '@mui/material/TextField';
import { biorhythm } from '../components/Birth_Biorhythm';
import { cabara } from '../components/Birth_Cabara';
import bgImage from '../img/biorhythmBack.svg'
import bgImageYear from '../img/biorhythmBackYear.svg'
// import circleImg from '../img/circle.svg';
import {
    WeekName,
    Ninestar,
    EtoName10,
    EtoName12,
    EtoName12RubyA,
    ZodiacSignsRuby,
    ZodiacSigns12Search,
    cabaraData,
    cabaraDataSummary,
    cabaraDataStar,
    cabaraDataStarN,
    cabaraDataMeaning,
    cabaraDataPersonal,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import SVGNinestarAngleImg from '../components/ninestarAngleImg';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Koyomi {
    date: string;           /* 日付 */
    eto10_month: number;    /* 月干支（暦：10支）： */
    eto12_month: number;    /* 月干支（暦：12支） */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
    week: string;           /* 曜日 */
    eto10_day: number;      /* 日干支（10支） */
    eto12_day: number;      /* 日干支（12支） */
    kyuusei_y: number;      /* 年九星 */
    kyuusei_m: number;      /* 月九星 */
}

interface NinestarDaygb {
    date: string;           /* 日付 */
    kyuseiEveryday_total: string;
}

interface NinestarAngle {
    date: string;           /* 日付 */
    kyuseiAngle_ymd: string;
}

interface Celebrity {
    search_no: number;
    add_id: string;
    name: string;
    birth: string;
}



/* *********************************************************************************************** */
/* ***** ページ名の作成 *************************************************************************** */
/* *********************************************************************************************** */

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `*年*月*日生の情報・吉凶判断`
let pageTitleS = `情報・吉凶判断` // ページトップのナビゲーションリンク
let uppageTitle = `誕生日毎`


const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/birth"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

// function：月日から、星座を算出
function findZodiacSign(birthMD: string): string {
    for (const dateRange in ZodiacSigns12Search) {
        const [start, end] = dateRange.split("-");
        if (birthMD >= start && birthMD <= end) {
            return ZodiacSigns12Search[dateRange];
        }
    }
    return ""; // 該当するものがなかった場合
}

// function：年月日から、和暦を算出
function dateWareki(birthYMD: string, birthY: string): string {
    if (birthYMD < "19120729") {
        return "明治" + (parseInt(birthY, 10) - 1867).toString() + "年";
    } else if (birthYMD <= "19121231") {
        return "大正元年";
    } else if (birthYMD <= "19261224") {
        return "大正" + (parseInt(birthY, 10) - 1911).toString() + "年";
    } else if (birthYMD <= "19261231") {
        return "昭和元年";
    } else if (birthYMD <= "19890107") {
        return "昭和" + (parseInt(birthY, 10) - 1925).toString() + "年";
    } else if (birthYMD <= "19891231") {
        return "平成元年";
    } else if (birthYMD <= "20190430") {
        return "平成" + (parseInt(birthY, 10) - 1988).toString() + "年";
    } else if (birthYMD <= "20191231") {
        return "令和元年";
    } else if (birthYMD >= "2019020190101") {
        return "令和" + (parseInt(birthY, 10) - 2018).toString() + "年";
    }
    return ""; // 該当するものがなかった場合
}

// function：年月日から、年齢を算出
function todayAge(birthY: string, birthM: string, birthD: string, todayY: string, todayM: string, todayD: string): string {
    // 年齢を計算
    let age = parseInt(todayY, 10) - parseInt(birthY, 10);
    // 誕生日がまだ来ていない場合は、1引く
    if (parseInt(todayM, 10) < parseInt(birthM, 10) || (parseInt(todayM, 10) === parseInt(birthM, 10) && parseInt(todayD, 10) < parseInt(birthD, 10))) {
        age -= 1;
    }
    // 年齢を文字列として返す
    return String(age);
}

function BirthCompatibleForm(birthF: string) {
    const [dateValueA, setDateValueA] = useState(birthF);
    const [dateValueB, setDateValueB] = useState(birthF);
    const [dateValueC, setDateValueC] = useState("");
    const [dateValueD, setDateValueD] = useState("");
    const [dateValueE, setDateValueE] = useState("");
    const [dateValueF, setDateValueF] = useState("");
    const navigate = useNavigate();

    const handleButtonClick = () => {
        // For this example, I'm just navigating to a dummy URL.
        // Adjust according to your needs.
        navigate(`/birth_compatible/${dateValueA}x${dateValueB}x${dateValueC}x${dateValueD}x${dateValueE}x${dateValueF}`);
    };

    return (
        <form>
            <span style={{ marginTop: '1rem', fontSize: '0.8rem', color: 'gray' }}>1人目の生年月日</span>
            <TextField
                disabled
                margin="dense"
                id="partnerA"
                label=""
                type="date"
                fullWidth
                variant="outlined"
                value={dateValueA}
                onChange={(e) => setDateValueA(e.target.value)}
                inputProps={{ min: "1901-01-01", max: "2099-12-31", style: { textAlign: 'left', backgroundColor: 'white', margin: '0.3rem', letterSpacing: '0.1rem' } }}
            />
            <span style={{ marginTop: '1rem', fontSize: '0.8rem', color: 'gray' }}>2人目の生年月日（必須）</span>
            <TextField
                required
                margin="dense"
                id="partnerB"
                label=""
                type="date"
                fullWidth
                variant="outlined"
                value={dateValueB}
                onChange={(e) => setDateValueB(e.target.value)}
                inputProps={{ min: "1901-01-01", max: "2099-12-31", style: { textAlign: 'left', backgroundColor: 'white', margin: '0.3rem', letterSpacing: '0.1rem' } }}
            />
            <span style={{ marginTop: '1rem', fontSize: '0.8rem', color: 'gray' }}>3人目の生年月日（任意）</span>
            <TextField
                margin="dense"
                id="partnerC"
                label=""
                type="date"
                fullWidth
                variant="outlined"
                value={dateValueC}
                onChange={(e) => setDateValueC(e.target.value)}
                inputProps={{ min: "1901-01-01", max: "2099-12-31", style: { textAlign: 'left', backgroundColor: 'white', margin: '0.3rem', letterSpacing: '0.1rem' } }}
            />
            <Button variant="contained" sx={{ marginTop: '1rem' }} onClick={handleButtonClick}>結果を表示する</Button>
        </form>
    );
}

declare const require: {
    context(directory: string, useSubdirectories: boolean, regExp: RegExp): any;
};


// // 画像表示の倍率
// let canvasScale = 0.5;
// let canvasWidth = 680 * canvasScale;
// let canvasheight = 830 * canvasScale;
// let canvastableheight = "400px";
// let canvastablebgcolor = "#ddd";
// // 小さいスマホ
// canvasScale = 0.5;
// canvasWidth = 680 * canvasScale;
// canvasheight = 830 * canvasScale;
// canvastableheight = "360px";
// canvastablebgcolor = "#ddd";

const Birthday: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [canvasScale] = useState(0.65);

    const canvasWidth = 680 * canvasScale;
    const canvasheight = 830 * canvasScale;
    const canvastableheight = "550px";
    const canvastablebgcolor = "#ddd";

    let { date } = useParams<Record<string, string>>();

    // 数字以外を削除
    if (date !== undefined) {
        date = date.replace(/\D/g, '');
    }

    const today = new Date();
    const todayY = today.getFullYear().toString(); // 年
    const todayM = (today.getMonth() + 1).toString().padStart(2, '0'); // 月（0-11なので+1が必要）
    const todayD = today.getDate().toString().padStart(2, '0'); // 日
    const todayYMD = todayY + todayM + todayD;
    // const todayW = today.getDay();
    const agoYearYMD = Number(todayYMD) - 10000;

    let birthYMD = null;
    let birthY = null;
    let birthM = null;
    let birthD = null;
    let birthF = null;
    let birthMD = null;
    let birthKJ = null;
    let birthKN: string;
    let errMessage = "";

    if (date === "") {
        birthYMD = "19010101";
        birthY = birthYMD.substring(0, 4);
        birthM = birthYMD.substring(4, 6);
        birthD = birthYMD.substring(6, 8);
        birthF = birthY + "-" + birthM + "-" + birthD;
        birthMD = birthYMD.substring(4, 8);
        errMessage = "生年月日が把握できないため、1901年1月1日生まれで算出しております。";
    } else if (date !== undefined) {
        birthYMD = date.substring(0, 8);
        birthY = birthYMD.substring(0, 4);
        birthM = birthYMD.substring(4, 6);
        birthD = birthYMD.substring(6, 8);
        birthF = birthY + "-" + birthM + "-" + birthD;
        birthMD = birthYMD.substring(4, 8);
    } else {
        birthY = new Date().getFullYear();
        birthM = (new Date().getMonth() + 1).toString().padStart(2, '0');
        birthD = (new Date().getDate()).toString().padStart(2, '0');
        birthF = birthY + "-" + birthM + "-" + birthD;
        birthYMD = birthY + birthM + birthD;
        birthMD = birthYMD.substring(4, 8);
    }

    if (parseInt(birthYMD) < 19010101) {
        birthYMD = "19010101";
        birthY = birthYMD.substring(0, 4);
        birthM = birthYMD.substring(4, 6);
        birthD = birthYMD.substring(6, 8);
        birthF = birthY + "-" + birthM + "-" + birthD;
        birthMD = birthYMD.substring(4, 8);
        errMessage = "生年月日が1901年1月1日より以前と思われる為、1901年1月1日生まれで算出しております。";
    }
    if (parseInt(birthYMD) > 20901231) {
        birthYMD = "20901231";
        birthY = birthYMD.substring(0, 4);
        birthM = birthYMD.substring(4, 6);
        birthD = birthYMD.substring(6, 8);
        birthF = birthY + "-" + birthM + "-" + birthD;
        birthMD = birthYMD.substring(4, 8);
        errMessage = "生年月日が2090年12月31日より以降と思われる為、2090年12月31日生まれで算出しております。";
    }

    let birthBasicA = "";
    let birthBasicB = "";
    let birthBasicC = "";
    let birthBiorhythm = "";
    let birthCabaraA = "";
    let birthCabaraB = "";
    let birthCabaraC = "";
    let birthCabaraD = "";
    // let birthNinestarAngleTitle01 = ""; // -7日
    // let birthNinestarAngleTitle02 = ""; // -6日
    // let birthNinestarAngleTitle03 = ""; // -5日
    // let birthNinestarAngleTitle04 = ""; // -4日
    // let birthNinestarAngleTitle05 = ""; // -3日
    let birthNinestarAngleTitle06 = ""; // -2日
    let birthNinestarAngleTitle07 = ""; // -1日
    let birthNinestarAngleTitle08 = ""; // 今日
    let birthNinestarAngleTitle09 = ""; // +1日
    let birthNinestarAngleTitle10 = ""; // +2日
    let birthNinestarAngleTitle11 = ""; // +3日
    let birthNinestarAngleTitle12 = ""; // +4日
    let birthNinestarAngleTitle13 = ""; // +5日
    let birthNinestarAngleTitle14 = ""; // +6日
    let birthNinestarAngleTitle15 = ""; // +7日

    // let birthNinestarAngleDATA01 = "";
    // let birthNinestarAngleDATA02 = "";
    // let birthNinestarAngleDATA03 = "";
    // let birthNinestarAngleDATA04 = "";
    // let birthNinestarAngleDATA05 = "";
    let birthNinestarAngleDATA06 = "";
    let birthNinestarAngleDATA07 = "";
    let birthNinestarAngleDATA08 = "";
    let birthNinestarAngleDATA09 = "";
    let birthNinestarAngleDATA10 = "";
    let birthNinestarAngleDATA11 = "";
    let birthNinestarAngleDATA12 = "";
    let birthNinestarAngleDATA13 = "";
    let birthNinestarAngleDATA14 = "";
    let birthNinestarAngleDATA15 = "";


    let birthAspect = "";
    let birthHuman = "";

    let birthHumanList: ReactNode[] = [];

    // バイオリズムのグラフ
    let biorhythmDataP = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let biorhythmDataS = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let biorhythmDataI = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    // 九星のグラフ
    let ninestarData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let ninestarDataA = [-3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3];
    let ninestarDataB = [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3];

    let birthNinestarD01 = null; // -7日
    let birthNinestarD02 = null; // -6日
    let birthNinestarD03 = null; // -5日
    let birthNinestarD04 = null; // -4日
    let birthNinestarD05 = null; // -3日
    let birthNinestarD06 = null; // -2日
    let birthNinestarD07 = null; // -1日
    let birthNinestarD08 = null; // 今日
    let birthNinestarD09 = null; // +1日
    let birthNinestarD10 = null; // +2日
    let birthNinestarD11 = null; // +3日
    let birthNinestarD12 = null; // +4日
    let birthNinestarD13 = null; // +5日
    let birthNinestarD14 = null; // +6日
    let birthNinestarD15 = null; // +7日
    let birthNinestarD16 = null; // +8日
    let birthNinestarD17 = null; // +9日
    let birthNinestarD18 = null; // +10日
    let birthNinestarD19 = null; // +11日
    let birthNinestarD20 = null; // +12日
    let birthNinestarD21 = null; // +13日
    let birthNinestarD22 = null; // +14日

    // カバラのグラフ
    let cabaraDataYear = [
        0, 0, 0, 0, 0,
        0, 0, 0, 0, 0,
        0];

    let birthCabaraY01 = null // -5年
    let birthCabaraY02 = null // -4年
    let birthCabaraY03 = null // -3年
    let birthCabaraY04 = null // -2年
    let birthCabaraY05 = null // -1年
    let birthCabaraY06 = null // 今年
    let birthCabaraY07 = null // +1年
    let birthCabaraY08 = null // +2年
    let birthCabaraY09 = null // +3年
    let birthCabaraY10 = null // +4年
    let birthCabaraY11 = null // +5年

    // const biorhythmLabels = ['7日前', '-6', '-5', '-4', '-3', '-2', '-1', '今日', '+1', '+2', '+3', '+4', '+5', '+6', '7日後', '+8', '+9', '+10', '+11', '+12', '+13', '14日後',];
    const biorhythmLabels = Array.from({ length: 22 }, (_, i) => {
        const newDate = new Date(today);
        newDate.setDate(today.getDate() + i - 7); // 7日前からスタート

        // 月と日を取得
        // const month = newDate.getMonth() + 1; // JavaScriptの月は0から始まるため+1
        const date = newDate.getDate();

        return `${date}日`;
    });

    const biorhythmYearLabels = Array.from({ length: 11 }, (_, i) => {
        return `${i + Number(todayY) - 5}年`;
    });



    // if (parseInt(birthYMD) >= 19010101 && parseInt(birthYMD) <= 20901231) {

    // } else {
    //     ///////////////////////////////////////////////////////////
    //     // 誕生日に問題あり
    //     birthBasicA = "誕生日（生年月日）が把握出来ません。";
    //     birthBiorhythm = "誕生日（生年月日）が把握出来ません。";
    //     birthCabaraA = "誕生日（生年月日）が把握出来ません。";
    //     birthNinestarAngleTitle08 = "";
    //     birthNinestarAngleTxt08 = "誕生日（生年月日）が把握出来ません。";
    //     birthHuman = "誕生日（生年月日）が把握出来ません。";
    //     birthAspect = "誕生日（生年月日）が把握出来ません。";
    //     birthKJ = "**星";
    //     birthKN = "*";
    //     birthY = "*";
    //     birthM = "*";
    //     birthD = "*";
    // }

    ///////////////////////////////////////////////////////////
    // 誕生日に問題なし


    // 今日の暦jsonの呼び出し //////////////////////////////////////////////////////////////////////

    // const calendar_json_url = "https://db.suureki.net/_db_calendar/calendar_" + `${birthY}` + ".json";
    const calendar_json_url = `https://db.suureki.net/_db_calendar/calendar_${birthY}.json`;

    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    useEffect(() => {
        fetch(calendar_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(koyomi_data => setDataKoyomi(koyomi_data))
            .catch(error => console.error('Error:', error));
    }, [calendar_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let weekMap: { [date: string]: number } = {};
    let eto10dayMap: { [date: string]: number } = {};
    let eto12dayMap: { [date: string]: number } = {};
    let eto10yearMap: { [date: string]: number } = {};
    let eto12yearMap: { [date: string]: number } = {};
    let kyuuseiyearMap: { [date: string]: number } = {};
    koyomi_data.forEach(koyomi => {
        weekMap[koyomi.date] = Number(koyomi.week);
        eto10dayMap[koyomi.date] = koyomi.eto10_day;
        eto12dayMap[koyomi.date] = koyomi.eto12_day;
        eto10yearMap[koyomi.date] = koyomi.eto10_year;
        eto12yearMap[koyomi.date] = koyomi.eto12_year;
        kyuuseiyearMap[koyomi.date] = koyomi.kyuusei_y;
    });
    birthKJ = Ninestar[kyuuseiyearMap[birthYMD]];
    birthKN = String(kyuuseiyearMap[birthYMD]);

    const kyuuseiNo = kyuuseiyearMap[birthYMD];
    // console.log("birthKN:" + birthKN)

    // 九星の吉凶運勢の呼び出し //////////////////////////////////////////////////////////////////////

    // const ninestar_daygb_json_url = "https://db.suureki.net/_db_fortune_9star_daygb/KE_db_" + `${todayY}${todayM}` + ".json";
    const ninestar_daygb_json_url = `https://db.suureki.net/_db_fortune_9star_daygb/KE_db_${todayY}${todayM}.json`;

    const [ninestardaygb_data, setDataNinestarDaygb] = useState<NinestarDaygb[]>([]);
    useEffect(() => {
        fetch(ninestar_daygb_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(ninestardaygb_data => setDataNinestarDaygb(ninestardaygb_data))
            .catch(error => console.error('Error:', error));
    });
    // JSONデータを日付をキーとするオブジェクトに変換します。
    let ninestarDaygbMap: { [date: string]: string } = {};
    ninestardaygb_data.forEach(ninestarB => {
        ninestarDaygbMap[ninestarB.date] = String(ninestarB.kyuseiEveryday_total);
    }, [birthY]);


    // 九星の吉方位の呼び出し //////////////////////////////////////////////////////////////////////

    let ninestar_angle_json_url = "";
    // kyuuseiyearMap[birthYMD] = 0;
    if (kyuuseiNo !== undefined) {
        // ninestar_angle_json_url = "https://db.suureki.net/_db_fortune_9star_angle/KL_db_" + `${todayY}${todayM}` + "_" + kyuuseiyearMap[birthYMD] + ".json";
        ninestar_angle_json_url = `https://db.suureki.net/_db_fortune_9star_angle/KL_db_${todayY}${todayM}_${kyuuseiyearMap[birthYMD]}.json`;
    } else {
        // ninestar_angle_json_url = "https://db.suureki.net/_db_fortune_9star_angle/KL_db_" + `${todayY}${todayM}` + "_0.json";
        ninestar_angle_json_url = `https://db.suureki.net/_db_fortune_9star_angle/KL_db_${todayY}${todayM}_0.json`;
    }
    const [ninestarangle_data, setDataNinestarAngle] = useState<NinestarAngle[]>([]);
    useEffect(() => {
        fetch(ninestar_angle_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(ninestarangle_data => setDataNinestarAngle(ninestarangle_data))
            .catch(error => console.error('Error:', error));
    });
    // JSONデータを日付をキーとするオブジェクトに変換します。
    let ninestarAngleMap: { [date: string]: string } = {};
    ninestarangle_data.forEach(ninestarA => {
        ninestarAngleMap[ninestarA.date] = String(ninestarA.kyuseiAngle_ymd);
    }, [birthY]);



    // 著名人jsonの呼び出し //////////////////////////////////////////////////////////////////////

    // http://localhost/_db_celebrity/_birth/celebrity_0000-11-19.json
    // https://db.suureki.net/_db_celebrity/_birth/celebrity_0000-11-19.json

    // const celebrity_json_url = "https://db.suureki.net/_db_celebrity/_birth/celebrity_0000-" + `${birthM}` + "-" + `${birthD}` + ".json";
    // const celebrity_json_url = `https://db.suureki.net/_db_celebrity/_birth/celebrity_0000-${birthM}-${birthD}.json`;
    // const [celebrity_data, setDatacelebrity] = useState<Celebrity[]>([]);
    // useEffect(() => {
    //     fetch(celebrity_json_url)
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //         })
    //         .then(celebrity_data => setDatacelebrity(celebrity_data))
    //         .catch(error => console.error('Error:', error));
    // });
    // // JSONデータを日付をキーとするオブジェクトに変換します。
    // let addidMap: { [search_no: number]: string } = {};
    // let nameMap: { [search_no: number]: string } = {};
    // let birthMap: { [search_no: number]: string } = {};
    // celebrity_data.forEach(celebrity => {
    //     addidMap[celebrity.search_no] = celebrity.add_id;
    //     nameMap[celebrity.search_no] = celebrity.name;
    //     birthMap[celebrity.search_no] = celebrity.birth;
    // }, [birthYMD]);

    ////////////////////////////////////////////////////////////////////////


    ///////////////////////////////////////////////////////////
    // ＊＊星人の今年の年盤の座相
    // 本命殺、本命的殺

    // if (birthKN !== undefined) {
    // }

    // console.log("birthKN:" + birthKN);

    useEffect(() => {
        // const filename = "./canvasScripts/zasoudata_canvas.tsx";
        // const filename = `./canvasScripts/zasoudata_canvas_2024a.tsx`;
        const filename = `./canvasScripts/zasoudata_canvas_${todayY}a.tsx`;

        const drawCanvas = async () => {
            if (!canvasRef.current) return;
            try {
                const context = require.context('./canvasScripts', false, /\.tsx$/);
                const module = await context(`./${filename.split('/').pop()}`);
                if (module.default) {
                    // module.default(canvasScale, canvasScale, canvasRef.current);
                    module.default(canvasScale, canvasScale, birthKN);
                }
            } catch (err) {
                console.error("Failed to load module:", err);
            }
        };

        drawCanvas();
    }, [canvasScale, todayY, birthKN, canvasRef]);



    if (WeekName[weekMap[birthYMD]] !== undefined) {

        pageTitle = `${birthY}年${parseInt(birthM, 10)}月${parseInt(birthD, 10)}日生まれの情報・吉凶判断`

        const zodiacSign = parseInt(findZodiacSign(birthMD), 10);
        const kazoeAge = Number(todayY) - Number(birthY) + 1;

        ///////////////////////////////////////////////////////////
        // 基本情報の作成

        birthBasicA = `<span>${birthY}年（${dateWareki(birthYMD, String(birthY))}）</span><span>${parseInt(birthM, 10)}月</span><span>${parseInt(birthD, 10)}日</span><span>（${WeekName[weekMap[birthYMD]]}）生まれ</span>`;
        birthBasicB = `<span>${todayY}年${parseInt(todayM, 10)}月${parseInt(todayD, 10)}日現在、</span><span>満年齢は ${todayAge(String(birthY), birthM, birthD, todayY, todayM, todayD)}歳。数え年は ${kazoeAge}歳。</span>`;
        birthBasicC = `<span>${EtoName12RubyA[eto12yearMap[birthYMD]]}年（${EtoName10[eto10yearMap[birthYMD]]}${EtoName12[eto12yearMap[birthYMD]]}）生まれ、</span><span>${ZodiacSignsRuby[zodiacSign]}（12星座）、</span><span>${Ninestar[kyuuseiyearMap[birthYMD]]}人（年九星）。</span>`;


        ///////////////////////////////////////////////////////////
        // バイオリズムの作成

        // 誕生日が、1年前より前ならOK
        if (agoYearYMD >= Number(birthYMD)) {
            const { p: biorhythmAnswerP, s: biorhythmAnswerS, i: biorhythmAnswerI } = biorhythm(String(birthY), birthM, birthD, todayY, todayM, todayD);
            biorhythmDataP = [
                biorhythmAnswerP.p01, biorhythmAnswerP.p02, biorhythmAnswerP.p03, biorhythmAnswerP.p04, biorhythmAnswerP.p05,
                biorhythmAnswerP.p06, biorhythmAnswerP.p07, biorhythmAnswerP.p08, biorhythmAnswerP.p09, biorhythmAnswerP.p10,
                biorhythmAnswerP.p11, biorhythmAnswerP.p12, biorhythmAnswerP.p13, biorhythmAnswerP.p14, biorhythmAnswerP.p15,
                biorhythmAnswerP.p16, biorhythmAnswerP.p17, biorhythmAnswerP.p18, biorhythmAnswerP.p19, biorhythmAnswerP.p20,
                biorhythmAnswerP.p21, biorhythmAnswerP.p22
            ];
            biorhythmDataS = [
                biorhythmAnswerS.s01, biorhythmAnswerS.s02, biorhythmAnswerS.s03, biorhythmAnswerS.s04, biorhythmAnswerS.s05,
                biorhythmAnswerS.s06, biorhythmAnswerS.s07, biorhythmAnswerS.s08, biorhythmAnswerS.s09, biorhythmAnswerS.s10,
                biorhythmAnswerS.s11, biorhythmAnswerS.s12, biorhythmAnswerS.s13, biorhythmAnswerS.s14, biorhythmAnswerS.s15,
                biorhythmAnswerS.s16, biorhythmAnswerS.s17, biorhythmAnswerS.s18, biorhythmAnswerS.s19, biorhythmAnswerS.s20,
                biorhythmAnswerS.s21, biorhythmAnswerS.s22
            ];
            biorhythmDataI = [
                biorhythmAnswerI.i01, biorhythmAnswerI.i02, biorhythmAnswerI.i03, biorhythmAnswerI.i04, biorhythmAnswerI.i05,
                biorhythmAnswerI.i06, biorhythmAnswerI.i07, biorhythmAnswerI.i08, biorhythmAnswerI.i09, biorhythmAnswerI.i10,
                biorhythmAnswerI.i11, biorhythmAnswerI.i12, biorhythmAnswerI.i13, biorhythmAnswerI.i14, biorhythmAnswerI.i15,
                biorhythmAnswerI.i16, biorhythmAnswerI.i17, biorhythmAnswerI.i18, biorhythmAnswerI.i19, biorhythmAnswerI.i20,
                biorhythmAnswerI.i21, biorhythmAnswerI.i22
            ];
            birthBiorhythm = "";
        } else {
            birthBiorhythm = "1年前より過去の誕生日のみ、表示できます。";
        }

        ///////////////////////////////////////////////////////////
        // カバラ数秘術の作成

        const biorhythmAnswer = cabara(String(birthYMD), todayY);
        // console.log(biorhythmAnswer.cabaraAnswer)
        // console.log(biorhythmAnswer.cabaraAnswerF)
        // console.log(biorhythmAnswer.cabaraAnswerT)
        // console.log(biorhythmAnswer.cabaraAnswerTF)

        birthCabaraA = biorhythmAnswer.cabaraAnswer;
        birthCabaraB = biorhythmAnswer.cabaraAnswerF;
        birthCabaraC = cabaraDataPersonal[biorhythmAnswer.cabaraAnswer];
        birthCabaraD = "" + biorhythmAnswer.cabaraAnswerTF;

        birthCabaraY01 = Number(biorhythmAnswer.cabaraAnswerT) - 5; // -3年
        birthCabaraY02 = Number(biorhythmAnswer.cabaraAnswerT) - 4; // -3年
        birthCabaraY03 = Number(biorhythmAnswer.cabaraAnswerT) - 3; // -3年
        birthCabaraY04 = Number(biorhythmAnswer.cabaraAnswerT) - 2; // -2年
        birthCabaraY05 = Number(biorhythmAnswer.cabaraAnswerT) - 1; // -1年
        birthCabaraY06 = Number(biorhythmAnswer.cabaraAnswerT); // 今年
        birthCabaraY07 = Number(biorhythmAnswer.cabaraAnswerT) + 1; // +1年
        birthCabaraY08 = Number(biorhythmAnswer.cabaraAnswerT) + 2; // +2年
        birthCabaraY09 = Number(biorhythmAnswer.cabaraAnswerT) + 3; // +3年
        birthCabaraY10 = Number(biorhythmAnswer.cabaraAnswerT) + 4; // +4年
        birthCabaraY11 = Number(biorhythmAnswer.cabaraAnswerT) + 5; // +5年

        if (birthCabaraY01 <= 0) { birthCabaraY01 += 9; }
        if (birthCabaraY02 <= 0) { birthCabaraY02 += 9; }
        if (birthCabaraY03 <= 0) { birthCabaraY03 += 9; }
        if (birthCabaraY04 <= 0) { birthCabaraY04 += 9; }
        if (birthCabaraY05 <= 0) { birthCabaraY05 += 9; }
        if (birthCabaraY06 <= 0) { birthCabaraY06 += 9; }
        if (birthCabaraY07 <= 0) { birthCabaraY07 += 9; }
        if (birthCabaraY08 <= 0) { birthCabaraY08 += 9; }
        if (birthCabaraY09 <= 0) { birthCabaraY09 += 9; }
        if (birthCabaraY10 <= 0) { birthCabaraY10 += 9; }
        if (birthCabaraY11 <= 0) { birthCabaraY11 += 9; }

        if (birthCabaraY01 >= 10) { birthCabaraY01 -= 9; }
        if (birthCabaraY02 >= 10) { birthCabaraY02 -= 9; }
        if (birthCabaraY03 >= 10) { birthCabaraY03 -= 9; }
        if (birthCabaraY04 >= 10) { birthCabaraY04 -= 9; }
        if (birthCabaraY05 >= 10) { birthCabaraY05 -= 9; }
        if (birthCabaraY06 >= 10) { birthCabaraY06 -= 9; }
        if (birthCabaraY07 >= 10) { birthCabaraY07 -= 9; }
        if (birthCabaraY08 >= 10) { birthCabaraY08 -= 9; }
        if (birthCabaraY09 >= 10) { birthCabaraY09 -= 9; }
        if (birthCabaraY10 >= 10) { birthCabaraY10 -= 9; }
        if (birthCabaraY11 >= 10) { birthCabaraY11 -= 9; }

        cabaraDataYear = [
            Number(cabaraDataStarN[birthCabaraY01]),
            Number(cabaraDataStarN[birthCabaraY02]),
            Number(cabaraDataStarN[birthCabaraY03]),
            Number(cabaraDataStarN[birthCabaraY04]),
            Number(cabaraDataStarN[birthCabaraY05]),
            Number(cabaraDataStarN[birthCabaraY06]),
            Number(cabaraDataStarN[birthCabaraY07]),
            Number(cabaraDataStarN[birthCabaraY08]),
            Number(cabaraDataStarN[birthCabaraY09]),
            Number(cabaraDataStarN[birthCabaraY10]),
            Number(cabaraDataStarN[birthCabaraY11])
        ];

        ///////////////////////////////////////////////////////////
        // ＊＊星人の吉凶運勢の作成


        const birthNinestarYMD: string[] = [];
        const birthNinestarYMDJ: string[] = [];
        const birthNinestarCurrentDate = new Date(); // 現在の日付を取得
        birthNinestarCurrentDate.setDate(birthNinestarCurrentDate.getDate() - 7); // 7日前に設定
        let tmpYMD;
        let tmpW;

        for (let i = 0; i < 22; i++) { // 7日前から14日後までの22日間
            const tmpYyear = birthNinestarCurrentDate.getFullYear();
            const tmpMonth = (birthNinestarCurrentDate.getMonth() + 1).toString().padStart(2, '0'); // 1桁の場合、0で埋める
            const tmpDay = birthNinestarCurrentDate.getDate().toString().padStart(2, '0'); // 1桁の場合、0で埋める
            birthNinestarYMD[i] = `${tmpYyear}${tmpMonth}${tmpDay}`; // ninestar配列に年月日を割り当て
            birthNinestarCurrentDate.setDate(birthNinestarCurrentDate.getDate() + 1); // 翌日に設定
            tmpYMD = new Date(tmpYyear, (Number(tmpMonth) - 1), Number(tmpDay));
            tmpW = tmpYMD.getDay();

            birthNinestarYMDJ[i] = `${tmpYyear}年${parseInt(tmpMonth, 10)}月${parseInt(tmpDay, 10)}日（` + WeekName[tmpW] + `）`;
        }


        // console.log(birthNinestarD01);

        /*
            "date": "19051001",
            "kyuseiEveryday_total": "321123323"
        */

        // console.log(kyuuseiyearMap[19750101]);
        birthNinestarD01 = (ninestarDaygbMap[birthNinestarYMD[0]] ? ninestarDaygbMap[birthNinestarYMD[0]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD02 = (ninestarDaygbMap[birthNinestarYMD[1]] ? ninestarDaygbMap[birthNinestarYMD[1]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD03 = (ninestarDaygbMap[birthNinestarYMD[2]] ? ninestarDaygbMap[birthNinestarYMD[2]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD04 = (ninestarDaygbMap[birthNinestarYMD[3]] ? ninestarDaygbMap[birthNinestarYMD[3]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD05 = (ninestarDaygbMap[birthNinestarYMD[4]] ? ninestarDaygbMap[birthNinestarYMD[4]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD06 = (ninestarDaygbMap[birthNinestarYMD[5]] ? ninestarDaygbMap[birthNinestarYMD[5]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD07 = (ninestarDaygbMap[birthNinestarYMD[6]] ? ninestarDaygbMap[birthNinestarYMD[6]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD08 = (ninestarDaygbMap[birthNinestarYMD[7]] ? ninestarDaygbMap[birthNinestarYMD[7]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD09 = (ninestarDaygbMap[birthNinestarYMD[8]] ? ninestarDaygbMap[birthNinestarYMD[8]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD10 = (ninestarDaygbMap[birthNinestarYMD[9]] ? ninestarDaygbMap[birthNinestarYMD[9]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD11 = (ninestarDaygbMap[birthNinestarYMD[10]] ? ninestarDaygbMap[birthNinestarYMD[10]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD12 = (ninestarDaygbMap[birthNinestarYMD[11]] ? ninestarDaygbMap[birthNinestarYMD[11]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD13 = (ninestarDaygbMap[birthNinestarYMD[12]] ? ninestarDaygbMap[birthNinestarYMD[12]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD14 = (ninestarDaygbMap[birthNinestarYMD[13]] ? ninestarDaygbMap[birthNinestarYMD[13]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD15 = (ninestarDaygbMap[birthNinestarYMD[14]] ? ninestarDaygbMap[birthNinestarYMD[14]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD16 = (ninestarDaygbMap[birthNinestarYMD[15]] ? ninestarDaygbMap[birthNinestarYMD[15]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD17 = (ninestarDaygbMap[birthNinestarYMD[16]] ? ninestarDaygbMap[birthNinestarYMD[16]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD18 = (ninestarDaygbMap[birthNinestarYMD[17]] ? ninestarDaygbMap[birthNinestarYMD[17]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD19 = (ninestarDaygbMap[birthNinestarYMD[18]] ? ninestarDaygbMap[birthNinestarYMD[18]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD20 = (ninestarDaygbMap[birthNinestarYMD[19]] ? ninestarDaygbMap[birthNinestarYMD[19]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD21 = (ninestarDaygbMap[birthNinestarYMD[20]] ? ninestarDaygbMap[birthNinestarYMD[20]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD22 = (ninestarDaygbMap[birthNinestarYMD[21]] ? ninestarDaygbMap[birthNinestarYMD[21]].substring(kyuuseiyearMap[birthYMD], kyuuseiyearMap[birthYMD] + 1) : '');
        birthNinestarD01 = birthNinestarD01.substring(0, 1);
        birthNinestarD02 = birthNinestarD02.substring(0, 1);
        birthNinestarD03 = birthNinestarD03.substring(0, 1);
        birthNinestarD04 = birthNinestarD04.substring(0, 1);
        birthNinestarD05 = birthNinestarD05.substring(0, 1);
        birthNinestarD06 = birthNinestarD06.substring(0, 1);
        birthNinestarD07 = birthNinestarD07.substring(0, 1);
        birthNinestarD08 = birthNinestarD08.substring(0, 1);
        birthNinestarD09 = birthNinestarD09.substring(0, 1);
        birthNinestarD10 = birthNinestarD10.substring(0, 1);
        birthNinestarD11 = birthNinestarD11.substring(0, 1);
        birthNinestarD12 = birthNinestarD12.substring(0, 1);
        birthNinestarD13 = birthNinestarD13.substring(0, 1);
        birthNinestarD14 = birthNinestarD14.substring(0, 1);
        birthNinestarD15 = birthNinestarD15.substring(0, 1);
        birthNinestarD16 = birthNinestarD16.substring(0, 1);
        birthNinestarD17 = birthNinestarD17.substring(0, 1);
        birthNinestarD18 = birthNinestarD18.substring(0, 1);
        birthNinestarD19 = birthNinestarD19.substring(0, 1);
        birthNinestarD20 = birthNinestarD20.substring(0, 1);
        birthNinestarD21 = birthNinestarD21.substring(0, 1);
        birthNinestarD22 = birthNinestarD22.substring(0, 1);

        // console.log(ninestarDaygbMap[birthNinestarYMD[0]] + " の、" + kyuuseiyearMap[birthYMD] + " は、" + birthNinestarD01);

        // 213313313 の、0 は、2
        // 213313313 の、1 は、13313313
        // 213313313 の、2 は、3313313
        // 213313313 の、3 は、313313
        // 213313313 の、4 は、13313
        // 213313313 の、5 は、3313
        // 213313313 の、6 は、313
        // 213313313 の、7 は、13
        // 213313313 の、8 は、3

        // 5 4 3 なら、2を引く、2 1 0 なら、3を引く
        if (Number(birthNinestarD01) >= 3) { birthNinestarD01 = Number(birthNinestarD01) - 2; } else { birthNinestarD01 = Number(birthNinestarD01) - 3 };
        if (Number(birthNinestarD02) >= 3) { birthNinestarD02 = Number(birthNinestarD02) - 2; } else { birthNinestarD02 = Number(birthNinestarD02) - 3 };
        if (Number(birthNinestarD03) >= 3) { birthNinestarD03 = Number(birthNinestarD03) - 2; } else { birthNinestarD03 = Number(birthNinestarD03) - 3 };
        if (Number(birthNinestarD04) >= 3) { birthNinestarD04 = Number(birthNinestarD04) - 2; } else { birthNinestarD04 = Number(birthNinestarD04) - 3 };
        if (Number(birthNinestarD05) >= 3) { birthNinestarD05 = Number(birthNinestarD05) - 2; } else { birthNinestarD05 = Number(birthNinestarD05) - 3 };
        if (Number(birthNinestarD06) >= 3) { birthNinestarD06 = Number(birthNinestarD06) - 2; } else { birthNinestarD06 = Number(birthNinestarD06) - 3 };
        if (Number(birthNinestarD07) >= 3) { birthNinestarD07 = Number(birthNinestarD07) - 2; } else { birthNinestarD07 = Number(birthNinestarD07) - 3 };
        if (Number(birthNinestarD08) >= 3) { birthNinestarD08 = Number(birthNinestarD08) - 2; } else { birthNinestarD08 = Number(birthNinestarD08) - 3 };
        if (Number(birthNinestarD09) >= 3) { birthNinestarD09 = Number(birthNinestarD09) - 2; } else { birthNinestarD09 = Number(birthNinestarD09) - 3 };
        if (Number(birthNinestarD10) >= 3) { birthNinestarD10 = Number(birthNinestarD10) - 2; } else { birthNinestarD10 = Number(birthNinestarD10) - 3 };
        if (Number(birthNinestarD11) >= 3) { birthNinestarD11 = Number(birthNinestarD11) - 2; } else { birthNinestarD11 = Number(birthNinestarD11) - 3 };
        if (Number(birthNinestarD12) >= 3) { birthNinestarD12 = Number(birthNinestarD12) - 2; } else { birthNinestarD12 = Number(birthNinestarD12) - 3 };
        if (Number(birthNinestarD13) >= 3) { birthNinestarD13 = Number(birthNinestarD13) - 2; } else { birthNinestarD13 = Number(birthNinestarD13) - 3 };
        if (Number(birthNinestarD14) >= 3) { birthNinestarD14 = Number(birthNinestarD14) - 2; } else { birthNinestarD14 = Number(birthNinestarD14) - 3 };
        if (Number(birthNinestarD15) >= 3) { birthNinestarD15 = Number(birthNinestarD15) - 2; } else { birthNinestarD15 = Number(birthNinestarD15) - 3 };
        if (Number(birthNinestarD16) >= 3) { birthNinestarD16 = Number(birthNinestarD16) - 2; } else { birthNinestarD16 = Number(birthNinestarD16) - 3 };
        if (Number(birthNinestarD17) >= 3) { birthNinestarD17 = Number(birthNinestarD17) - 2; } else { birthNinestarD17 = Number(birthNinestarD17) - 3 };
        if (Number(birthNinestarD18) >= 3) { birthNinestarD18 = Number(birthNinestarD18) - 2; } else { birthNinestarD18 = Number(birthNinestarD18) - 3 };
        if (Number(birthNinestarD19) >= 3) { birthNinestarD19 = Number(birthNinestarD19) - 2; } else { birthNinestarD19 = Number(birthNinestarD19) - 3 };
        if (Number(birthNinestarD20) >= 3) { birthNinestarD20 = Number(birthNinestarD20) - 2; } else { birthNinestarD20 = Number(birthNinestarD20) - 3 };
        if (Number(birthNinestarD21) >= 3) { birthNinestarD21 = Number(birthNinestarD21) - 2; } else { birthNinestarD21 = Number(birthNinestarD21) - 3 };
        if (Number(birthNinestarD22) >= 3) { birthNinestarD22 = Number(birthNinestarD22) - 2; } else { birthNinestarD22 = Number(birthNinestarD22) - 3 };

        ninestarData = [
            Number(birthNinestarD01), Number(birthNinestarD02), Number(birthNinestarD03), Number(birthNinestarD04), Number(birthNinestarD05),
            Number(birthNinestarD06), Number(birthNinestarD07), Number(birthNinestarD08), Number(birthNinestarD09), Number(birthNinestarD10),
            Number(birthNinestarD11), Number(birthNinestarD12), Number(birthNinestarD13), Number(birthNinestarD14), Number(birthNinestarD15),
            Number(birthNinestarD16), Number(birthNinestarD17), Number(birthNinestarD18), Number(birthNinestarD19), Number(birthNinestarD20),
            Number(birthNinestarD21), Number(birthNinestarD22)
        ];

        ///////////////////////////////////////////////////////////
        // ＊＊星人の吉方位の作成

        // birthNinestarAngleATitle = "" + todayY + "年" + parseInt(todayM, 10) + "月" + parseInt(todayD, 10) + "日（" + WeekName[todayW] + "）：<br />";

        // birthNinestarAngleTitle01 = birthNinestarYMDJ[0];   // 7日前の、吉方位カードのタイトル
        // birthNinestarAngleTitle02 = birthNinestarYMDJ[1];   // 6日前の、吉方位カードのタイトル
        // birthNinestarAngleTitle03 = birthNinestarYMDJ[2];   // 5日前の、吉方位カードのタイトル
        // birthNinestarAngleTitle04 = birthNinestarYMDJ[3];   // 4日前の、吉方位カードのタイトル
        // birthNinestarAngleTitle05 = birthNinestarYMDJ[4];   // 3日前の、吉方位カードのタイトル
        birthNinestarAngleTitle06 = birthNinestarYMDJ[5];   // 2日前の、吉方位カードのタイトル
        birthNinestarAngleTitle07 = birthNinestarYMDJ[6];   // 1日前の、吉方位カードのタイトル
        birthNinestarAngleTitle08 = birthNinestarYMDJ[7];   // 今日の、吉方位カードのタイトル
        birthNinestarAngleTitle09 = birthNinestarYMDJ[8];   // 1日後の、吉方位カードのタイトル
        birthNinestarAngleTitle10 = birthNinestarYMDJ[9];   // 2日後の、吉方位カードのタイトル
        birthNinestarAngleTitle11 = birthNinestarYMDJ[10];   // 3日後の、吉方位カードのタイトル
        birthNinestarAngleTitle12 = birthNinestarYMDJ[11];   // 4日後の、吉方位カードのタイトル
        birthNinestarAngleTitle13 = birthNinestarYMDJ[12];   // 5日後の、吉方位カードのタイトル
        birthNinestarAngleTitle14 = birthNinestarYMDJ[13];   // 6日後の、吉方位カードのタイトル
        birthNinestarAngleTitle15 = birthNinestarYMDJ[14];   // 7日後の、吉方位カードのタイトル

        // birthNinestarAngleDATA01 = ninestarAngleMap[birthNinestarYMD[0]];    // 7日前の、吉方位カードの24桁データ
        // birthNinestarAngleDATA02 = ninestarAngleMap[birthNinestarYMD[1]];    // 6日前の、吉方位カードの24桁データ
        // birthNinestarAngleDATA03 = ninestarAngleMap[birthNinestarYMD[2]];    // 5日前の、吉方位カードの24桁データ
        // birthNinestarAngleDATA04 = ninestarAngleMap[birthNinestarYMD[3]];    // 4日前の、吉方位カードの24桁データ
        // birthNinestarAngleDATA05 = ninestarAngleMap[birthNinestarYMD[4]];    // 3日前の、吉方位カードの24桁データ
        birthNinestarAngleDATA06 = ninestarAngleMap[birthNinestarYMD[5]];    // 2日前の、吉方位カードの24桁データ
        birthNinestarAngleDATA07 = ninestarAngleMap[birthNinestarYMD[6]];    // 1日前の、吉方位カードの24桁データ
        birthNinestarAngleDATA08 = ninestarAngleMap[birthNinestarYMD[7]];    // 今日の、吉方位カードの24桁データ
        birthNinestarAngleDATA09 = ninestarAngleMap[birthNinestarYMD[8]];    // 1日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA10 = ninestarAngleMap[birthNinestarYMD[9]];    // 2日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA11 = ninestarAngleMap[birthNinestarYMD[10]];    // 3日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA12 = ninestarAngleMap[birthNinestarYMD[11]];    // 4日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA13 = ninestarAngleMap[birthNinestarYMD[12]];    // 5日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA14 = ninestarAngleMap[birthNinestarYMD[13]];    // 6日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA15 = ninestarAngleMap[birthNinestarYMD[14]];    // 7日後の、吉方位カードの24桁データ


        birthAspect = "本命殺・本命的殺・五黄殺・暗剣殺は、大凶方位です。";

        ///////////////////////////////////////////////////////////
        // 同じ誕生日生まれの著名人

        birthHuman = "";

        // for (let key in nameMap) {
        //     if (nameMap.hasOwnProperty(key)) {
        //         // birthHuman += "<Link><span>" + nameMap[key] + "、</span></Link>";
        //         birthHumanList.push(
        //             <span key={addidMap[key]}>
        //                 <Link
        //                     component={RouterLink}
        //                     underline="hover"
        //                     color="primary"
        //                     to={`/celebrity/${addidMap[key]}`}
        //                     onClick={handleClick}
        //                 >
        //                     {nameMap[key]}
        //                 </Link>
        //                 、
        //             </span>
        //         );

        //     }
        // }



    } else {
        ///////////////////////////////////////////////////////////
        // 誕生日に問題あり
        birthBasicA = "誕生日（生年月日）が把握出来ません。";
        birthBiorhythm = "誕生日（生年月日）が把握出来ません。";
        birthCabaraA = "誕生日（生年月日）が把握出来ません。";
        birthNinestarAngleTitle08 = "";
        birthHuman = "誕生日（生年月日）が把握出来ません。";
        birthAspect = "誕生日（生年月日）が把握出来ません。";
        birthKJ = "**星";
        birthKN = "*";
        birthY = "*";
        birthM = "*";
        birthD = "*";
    }




    const navigate = useNavigate();

    const [openA, setOpenA] = useState(false);
    const handleClickOpenA = () => {
        setOpenA(true);
    };
    const handleCloseA = () => {
        setOpenA(false);
    };

    const [openB, setOpenB] = useState(false);
    const handleClickOpenB = () => {
        setOpenB(true);
    };
    const handleCloseB = () => {
        setOpenB(false);
    };

    const [openC, setOpenC] = useState(false);
    const handleClickOpenC = () => {
        setOpenC(true);
    };
    const handleCloseC = () => {
        setOpenC(false);
    };

    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <DemoContainer components={['MobileDatePicker']}>
                        <Box display="flex" alignItems="center" className='search_space' key='searchKeyD'>
                            誕生日の選択：
                            <MobileDatePicker
                                views={['year', 'month', 'day']}
                                defaultValue={dayjs(birthF)}
                                onChange={(newValue) => {
                                    if (newValue && newValue.isValid()) {
                                        const formattedDate = newValue.format('YYYYMMDD');
                                        navigate(`/birth/${formattedDate}`);
                                    }
                                }}
                                format="YYYY/MM/DD"
                                minDate={dayjs('1901-01-01')}
                                maxDate={dayjs('2090-12-31')}
                                localeText={{ toolbarTitle: '日暦の年月日' }}
                                slotProps={{
                                    toolbar: {
                                        toolbarPlaceholder: '__',
                                        toolbarFormat: 'YYYY年MM月DD日',
                                        hidden: false,
                                    }
                                }}
                            />
                        </Box>
                    </DemoContainer>
                </LocalizationProvider>
            </Box>
            <Box className='content_subbox_txt'>
                <div style={{ color: 'red' }}>{errMessage}</div>
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>基本情報</h2>
                <div>{birthBasicA && <div dangerouslySetInnerHTML={{ __html: birthBasicA }} />}</div>
                <div style={{ marginBottom: '1rem' }}>{birthBasicB && <div dangerouslySetInnerHTML={{ __html: birthBasicB }} />}</div>
                <div style={{ marginBottom: '1rem' }}>{birthBasicC && <div dangerouslySetInnerHTML={{ __html: birthBasicC }} />}</div>
                <div>
                    <span>
                        <Link component={RouterLink} underline="always" key="link_d" color="primary" to={`/calendar/${birthYMD}`} onClick={handleClick}>
                            この日の日暦
                        </Link>
                    </span>
                    <span>、</span>
                    <span>
                        <Link component={RouterLink} underline="always" key="link_m" color="primary" to={`/calendar/${birthY}${birthM}`} onClick={handleClick}>
                            月暦
                        </Link>
                    </span>
                    <span>、</span>
                    <span>
                        <Link component={RouterLink} underline="always" key="link_y" color="primary" to={`/calendar/${birthY}`} onClick={handleClick}>
                            年暦
                        </Link>
                    </span>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{birthKJ}人の日々の吉凶運勢</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem', fontSize: '0.7rem' }}>
                    <span>崇暦.netが独自に計算し、日々の吉凶を判断しております。グラフは、0より上が吉で下が凶を表します。数字が0より離れるほど強さが増します。</span>
                </Alert>
                <table style={{ width: '100%', tableLayout: 'fixed', marginTop: '-3rem' }}>
                    <tbody>
                        <tr>
                            <td style={{ height: '420px' }}>
                                <div style={{
                                    width: '100%',
                                    height: '320px',
                                    overflowY: 'auto',
                                    overflowX: 'auto'
                                }}>
                                    <LineChart
                                        sx={{ backgroundImage: `url(${bgImage})`, touchAction: 'pan-x' }}
                                        width={700}
                                        height={300}
                                        series={[
                                            { data: ninestarDataB, label: '吉・上限', color: '#a6d2e9' },
                                            { data: ninestarDataA, label: '凶・下限', color: '#ecb3bc' },
                                            { data: ninestarData, label: '日々の吉凶', color: '#3ab2b0' },
                                        ]}
                                        xAxis={[{ scaleType: 'point', data: biorhythmLabels }]}
                                    >
                                    </LineChart>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{birthY}年{parseInt(birthM, 10)}月{parseInt(birthD, 10)}日生のバイオリズム</h2>
                <div style={{ color: 'red' }}>
                    {birthBiorhythm}
                </div>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem', fontSize: '0.7rem' }}>
                    <span style={{ marginBottom: '0.5rem' }}>バイオリズムとは：人間には、<span>身体（P波）</span>、<span>感情（S波）</span>、<span>知性（I波）</span>の3つの波があり、この波を把握し日々の生活に活かすことが出来ると言われております。</span><br />
                </Alert>
                <table style={{ width: '100%', tableLayout: 'fixed', marginTop: '-3rem' }}>
                    <tbody>
                        <tr>
                            <td style={{ height: '420px' }}>
                                <div style={{
                                    width: '100%',
                                    height: '320px',
                                    overflowY: 'auto',
                                    overflowX: 'auto'
                                }}>
                                    <LineChart
                                        sx={{ backgroundImage: `url(${bgImage})`, touchAction: 'pan-x' }}
                                        width={700}
                                        height={300}
                                        series={[
                                            { data: biorhythmDataI, label: '感情', color: '#4797ff' },
                                            { data: biorhythmDataS, label: '知性', color: '#b103db' },
                                            { data: biorhythmDataP, label: '身体', color: '#3ab2b0' },
                                        ]}
                                        xAxis={[{ scaleType: 'point', data: biorhythmLabels }]}
                                    >
                                    </LineChart>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{birthKJ}人の日々の吉方位</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem', fontSize: '0.7rem' }}>
                    <span>崇暦.netが独自に計算し、日々の吉方位を判断しております。</span><br />
                    <span style={{ color: 'blue' }}>青字：吉方位</span>
                    <span>、</span>
                    <span style={{ color: 'red' }}>赤字：凶方位</span>
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            width: 230,
                            height: 250,
                        },
                    }}
                >
                    <Paper elevation={5} sx={{ padding: '15px' }}>
                        <div><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA08} /></div>
                        <div style={{ color: 'green', fontSize: '1.0rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>{birthNinestarAngleTitle08}、今日</div>
                    </Paper>
                    <Paper elevation={5} sx={{ padding: '15px' }}>
                        <div><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA09} /></div>
                        <div style={{ color: 'green', fontSize: '1.0rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>{birthNinestarAngleTitle09}、明日</div>
                    </Paper>
                    <Paper elevation={5} sx={{ padding: '15px' }}>
                        <div><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA10} /></div>
                        <div style={{ color: 'green', fontSize: '1.0rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>{birthNinestarAngleTitle10}、明後日</div>
                    </Paper>
                </Box>
                <div style={{ marginTop: '1rem' }}>
                    <Button variant="contained" onClick={handleClickOpenB}>
                        2日前から7日後を見る
                    </Button>
                    <Dialog
                        open={openB}
                        onClose={handleCloseB}
                        aria-labelledby="alert-ninestar"
                        aria-describedby="alert-ninestar-description"
                        maxWidth="xl"
                    >
                        <DialogContent>
                            <DialogTitle>
                                {birthKJ}人の吉方位
                            </DialogTitle>
                            <DialogContentText id="alert-ninestar-description">
                                <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA06} /></span><br />
                                <span style={{ color: 'green' }}>{birthNinestarAngleTitle06}（2日前）</span><br /><br />
                                <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA07} /></span><br />
                                <span style={{ color: 'green' }}>{birthNinestarAngleTitle07}（1日前）</span><br /><br />
                                <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA08} /></span><br />
                                <span style={{ color: 'green' }}>{birthNinestarAngleTitle08}（今日）</span><br /><br />
                                <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA09} /></span><br />
                                <span style={{ color: 'green' }}>{birthNinestarAngleTitle09}（1日後）</span><br /><br />
                                <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA10} /></span><br />
                                <span style={{ color: 'green' }}>{birthNinestarAngleTitle10}（2日後）</span><br /><br />
                                <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA11} /></span><br />
                                <span style={{ color: 'green' }}>{birthNinestarAngleTitle11}（3日後）</span><br /><br />
                                <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA12} /></span><br />
                                <span style={{ color: 'green' }}>{birthNinestarAngleTitle12}（4日後）</span><br /><br />
                                <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA13} /></span><br />
                                <span style={{ color: 'green' }}>{birthNinestarAngleTitle13}（5日後）</span><br /><br />
                                <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA14} /></span><br />
                                <span style={{ color: 'green' }}>{birthNinestarAngleTitle14}（6日後）</span><br /><br />
                                <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA15} /></span><br />
                                <span style={{ color: 'green' }}>{birthNinestarAngleTitle15}（7日後）</span><br /><br />
                            </DialogContentText >
                        </DialogContent >
                        <DialogActions>
                            <Button onClick={handleCloseB}>閉じる</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{birthKJ}人の今年の年盤の座相</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                    今年の年盤の座相に、{birthKJ}人の本命殺と本命的殺を加えた座相です。<br />
                    八方位に自身の星が存在しない場合、本命殺と本命的殺は存在しません。<br />
                    <Link component={RouterLink} underline="always" key="db_moon" color="primary" to={`/aspect/${todayY}`} onClick={handleClick}>
                        今年（{todayY}年）の年盤の座相はこちら
                    </Link><br />
                </Alert>
                <div style={{ marginTop: '1rem', marginBottom: '1rem', color: 'red' }}>
                    {birthAspect}
                </div>
                <table style={{ backgroundColor: canvastablebgcolor, width: '100%', tableLayout: 'fixed' }}>
                    <tbody>
                        <tr>
                            <td style={{ height: '200px' }}>
                                <div style={{
                                    width: '100%',
                                    height: canvastableheight,
                                    overflowY: 'auto',
                                    overflowX: 'auto'
                                }}>
                                    <canvas ref={canvasRef} id="kyusei_img_years" width={canvasWidth} height={canvasheight} style={{ maxWidth: 'none' }}></canvas>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>九星で見る他の方との相性</h2>
                <div>
                    <Button variant="contained" onClick={handleClickOpenC}>
                        入力欄の表示
                    </Button>
                    <Dialog
                        open={openC}
                        onClose={handleCloseC}
                        aria-labelledby="alert-ninestar"
                        aria-describedby="alert-ninestar-description"
                        maxWidth="xl"
                    >
                        <DialogContent>
                            <DialogContentText>
                                九星で見る他の方との相性
                            </DialogContentText>
                            {BirthCompatibleForm(birthF)}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseC}>閉じる</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>カバラ数秘術：個人の特徴</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem', fontSize: '0.7rem' }}>
                    <span>カバラ数秘術とは：生年月日を1桁づつ足して2桁にし、さらに足して1桁にしてその数字で個人の特徴を表す。2桁が「11」「22」「33」の場合、それ以上は足さない。</span>
                </Alert>
                <div style={{ color: 'green' }}>
                    {birthY}年{parseInt(birthM, 10)}月{parseInt(birthD, 10)}日生の誕生数（運命数）は「{birthCabaraA}」です。
                </div>
                <div style={{ color: 'gray', fontSize: '0.8rem', marginBottom: '1rem', marginLeft: '-0.5rem' }}>
                    {birthCabaraB}
                </div>
                <div>
                    誕生数（運命数）が{birthCabaraA}の人の特徴は、{birthCabaraC}
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>カバラ数秘術：年毎の運気と特徴</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem', fontSize: '0.7rem' }}>
                    <span>年毎のカバラ数秘術とは：占う年と生月日を1桁づつ足して2桁にし、さらに足して1桁にしてその数字でその年の特徴を表す。9年サイクルで一巡する。</span>
                </Alert>
                <table style={{ width: '100%', tableLayout: 'fixed', marginTop: '-3rem' }}>
                    <tbody>
                        <tr>
                            <td style={{ height: '420px' }}>
                                <div style={{
                                    width: '100%',
                                    height: '320px',
                                    overflowY: 'auto',
                                    overflowX: 'auto'
                                }}>
                                    <LineChart
                                        sx={{ backgroundImage: `url(${bgImageYear})`, touchAction: 'pan-x' }}
                                        width={600}
                                        height={300}
                                        series={[
                                            { data: cabaraDataYear, label: '年毎の運気', color: '#3ab2b0' },
                                        ]}
                                        xAxis={[{ scaleType: 'point', data: biorhythmYearLabels }]}
                                    >
                                    </LineChart>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div style={{ marginBottom: '1rem' }}>
                    <div style={{ marginBottom: '1rem', color: 'green' }}>
                        今年（{Number(todayY)}年）は{cabaraData[String(birthCabaraY06)]}の年で、{cabaraDataSummary[String(birthCabaraY06)]}です。
                    </div>
                    <div style={{ lineHeight: '1.5rem', marginBottom: '1rem' }}>
                        {cabaraDataMeaning[String(birthCabaraY06)]}
                    </div>
                    <div style={{ color: 'gray', fontSize: '0.8rem', marginLeft: '0rem' }}>
                        {birthCabaraD}
                    </div>
                </div>
                <hr className='hr_tooltop' style={{ marginBottom: '1rem' }} />
                <div style={{ marginBottom: '1rem' }}>
                    <div style={{ marginBottom: '1rem', color: 'green' }}>
                        来年（{Number(todayY) + 1}年）は{cabaraData[String(birthCabaraY07)]}の年で、{cabaraDataSummary[String(birthCabaraY07)]}です。
                    </div>
                    <div style={{ lineHeight: '1.5rem', marginBottom: '1rem' }}>
                        {cabaraDataMeaning[String(birthCabaraY07)]}
                    </div>
                </div>
                <div>
                    <Button variant="contained" onClick={handleClickOpenA}>
                        {Number(todayY) - 5}年~{Number(todayY) + 5}年の11年間を見る
                    </Button>
                    <Dialog
                        open={openA}
                        onClose={handleCloseA}
                        aria-labelledby="alert-cabara"
                        aria-describedby="alert-cabara-description"
                        maxWidth="xl"
                    >
                        <DialogContent>
                            <DialogTitle>
                                {birthY}年{parseInt(birthM, 10)}月{parseInt(birthD, 10)}日生
                            </DialogTitle>
                            <DialogContentText id="alert-cabara-description">
                                <span style={{ color: 'green' }}>{Number(todayY) - 5}年（5年前、{Number(todayY) - Number(birthY) - 6}歳~{Number(todayY) - Number(birthY) - 5}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY01)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY01)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY01)]}</span><br /><br />
                                <span style={{ color: 'green' }}>{Number(todayY) - 4}年（4年前、{Number(todayY) - Number(birthY) - 5}歳~{Number(todayY) - Number(birthY) - 4}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY02)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY02)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY02)]}</span><br /><br />
                                <span style={{ color: 'green' }}>{Number(todayY) - 3}年（3年前、{Number(todayY) - Number(birthY) - 4}歳~{Number(todayY) - Number(birthY) - 3}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY03)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY03)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY03)]}</span><br /><br />
                                <span style={{ color: 'green' }}>{Number(todayY) - 2}年（2年前、{Number(todayY) - Number(birthY) - 3}歳~{Number(todayY) - Number(birthY) - 2}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY04)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY04)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY04)]}</span><br /><br />
                                <span style={{ color: 'green' }}>{Number(todayY) - 1}年（1年前、{Number(todayY) - Number(birthY) - 2}歳~{Number(todayY) - Number(birthY) - 1}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY05)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY05)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY05)]}</span><br /><br />
                                <span style={{ color: 'green' }}>{Number(todayY) + 0}年（今年、{Number(todayY) - Number(birthY) - 1}歳~{Number(todayY) - Number(birthY) - 0}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY06)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY06)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY06)]}</span><br /><br />
                                <span style={{ color: 'green' }}>{Number(todayY) + 1}年（1年後、{Number(todayY) - Number(birthY) + 0}歳~{Number(todayY) - Number(birthY) + 1}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY07)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY07)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY07)]}</span><br /><br />
                                <span style={{ color: 'green' }}>{Number(todayY) + 2}年（2年後、{Number(todayY) - Number(birthY) + 1}歳~{Number(todayY) - Number(birthY) + 2}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY08)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY08)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY08)]}</span><br /><br />
                                <span style={{ color: 'green' }}>{Number(todayY) + 3}年（3年後、{Number(todayY) - Number(birthY) + 2}歳~{Number(todayY) - Number(birthY) + 3}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY09)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY09)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY09)]}</span><br /><br />
                                <span style={{ color: 'green' }}>{Number(todayY) + 4}年（4年後、{Number(todayY) - Number(birthY) + 3}歳~{Number(todayY) - Number(birthY) + 4}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY10)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY10)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY10)]}</span><br /><br />
                                <span style={{ color: 'green' }}>{Number(todayY) + 5}年（5年後、{Number(todayY) - Number(birthY) + 4}歳~{Number(todayY) - Number(birthY) + 5}歳）は</span><span style={{ color: 'green' }}>{cabaraData[String(birthCabaraY11)]}の年。</span><span style={{ color: 'DarkOrange' }}>{cabaraDataStar[String(birthCabaraY11)]}</span><br /><span>{cabaraDataMeaning[String(birthCabaraY11)]}</span><br /><br />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseA}>閉じる</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{parseInt(birthM, 10)}月{parseInt(birthD, 10)}日生まれの著名人</h2>
                <div>
                    <Link component={RouterLink} underline="always" key="db_horiday" color="primary" to={`/celebrity_birthsearch/${birthM}${birthD}`} onClick={handleClick}>
                        著名人生年月日を、{parseInt(birthM, 10)}月{parseInt(birthD, 10)}日生まれで検索
                    </Link>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "Birth";
    const pagePath = pagePathS[pageKey] + birthYMD;


    let metaDescription = birthY + '年' + Number(birthM) + '月' + Number(birthD) + '日' + pageDescription[pageKey];
    // metaDescriptionの****を、Number(birthM) + '月' + Number(birthD) + '日' に置換
    metaDescription = metaDescription.replace('****', Number(birthM) + '月' + Number(birthD) + '日');

    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // pageTitleの1文字目を把握
    // const pageTitleFirst = pageTitle.charAt(0);

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        // console.log(pagePath + ':', encryptedUid.substring(0, 3));
        // console.log('pageTitle:', pageTitle);

        if (pagelogWriteUrl && encryptedUid && pageTitle.charAt(0) !== "*") {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>
                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});

const App = () => (
    <AuthProvider>
        <Birthday />
    </AuthProvider>
);

export default App;