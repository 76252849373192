import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SuurekiAppBar from './SuurekiAppBar';
import SuurekiBottomNavigation from './SuurekiBottomNavigation';
import Home from './pages/Home';
import Search from './pages/Search';
import About from './pages/About';
import Contact from './pages/Contact';
import Sitemap from './pages/Sitemap';
import Jump from './pages/Jump';
import Calendar from './pages/Calendar';
import CalendarIndex from './pages/CalendarIndex';
import ArchivesIndex from './pages/ArchivesIndex';
import CalendarSearch from './pages/CalendarSearch';
import CalendarSearchIndex from './pages/CalendarSearchIndex';
import GoodDay from './pages/GoodDay';
import GoodDayIndex from './pages/GoodDayIndex';
import BuddhaFair from './pages/BuddhaFair';
import BuddhaFairIndex from './pages/BuddhaFairIndex';
import Birth from './pages/Birth';
import BirthCompatible from './pages/BirthCompatible';
import BirthIndex from './pages/BirthIndex';
import Privacy from './pages/Privacy';
import Update from './pages/Update';
import Mailmagazine from './pages/Mailmagazine';
import Application from './pages/Application';
import DB24sekki from './pages/DB24sekki';
import DBetcday from './pages/DBetcday';
import DBhoridayindex from './pages/DBhoridayindex';
import DBhoriday from './pages/DBhoridaypage';
import DBkoyomihenkan from './pages/DBkoyomihenkan';
import DBmoonIndex from './pages/DBmoon';
import DBmoon from './pages/DBmoonpage';
import DBrekigetsu from './pages/DBrekigetsu';
import DBcountingAge from './pages/DBcountingAge';
import DBschooleg from './pages/DBschooleg';
import DBzodiacsigns from './pages/DBzodiacsigns';
import DBadjpcalendar from './pages/DBadjpcalendar';
import AnnotationIndex from './pages/Annotationindex';
import Annotation12 from './pages/Annotation12';
import Annotation28 from './pages/Annotation28';
import Annotation24sekki from './pages/Annotation24sekki';
import Annotation72 from './pages/Annotation72';
import AnnotationSz from './pages/AnnotationSz';
import AnnotationEto from './pages/AnnotationEto';
import Aspect from './pages/Aspect';
import AspectIndex from './pages/AspectIndex';
import Celebrity from './pages/Celebrity';
import CelebrityIndex from './pages/CelebrityIndex';
import CelebritySeacrh from './pages/CelebritySearch';
import CelebrityBirthSearch from './pages/CelebrityBirthSearch';
import CelebritySeacrhIndex from './pages/CelebritySearchIndex';
import Navi from './pages/Navi';
import Mypage from './pages/Mypage';
import MypageBirthAdd from './pages/MypageBirthAdd';
import MypageBirthDelete from './pages/MypageBirthDelete';
import Test from './pages/test';
import Test2 from './pages/test2';
import Zzdgk5fk967fkdcdkuh from './pages/Zzdgk5fk967fkdcdkuh';
import Zzdgk5fk967fkdcdkuhMail from './pages/Zzdgk5fk967fkdcdkuhMail';
import Zzdgk5fk967fkdcdkuhSitemap from './pages/Zzdgk5fk967fkdcdkuhSiteMap';
import NotFoundPage from './components/ErrNotFound'; // 404ページ用コンポーネント
import './App.css';
import CookieConsent from 'react-cookie-consent';
// import { AuthProvider } from './contexts/AuthContext';
// import { GoogleSignIn } from './components/GoogleSignIn';
// import { UserProfile } from './components/UserProfile';
// import { useAuth } from './contexts/AuthContext';


// const AppContent = () => {
//   const { user, loading } = useAuth();

//   if (loading) {
//     return <div>Loading...</div>;
//   }
//   return (
//     <div className="container mx-auto max-w-md mt-10">
//       {user ? <UserProfile /> : <GoogleSignIn />}
//     </div>
//   );
// };

/*
    <AuthProvider>
      <AppContent />
    </AuthProvider>
*/


function ScrollTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
/*
クッキー同意ボタン掲載は、<Router>直下に
    <CookieConsent
      location="bottom"
      buttonText="同意する"
      declineButtonText="拒否"
      style={{ background: "#2B373B", color: "white", zIndex: 9999 }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px", marginTop: "2rem", marginBottom: "2rem" }}
      declineButtonStyle={{ background: "#f44336", color: "white" }}
      onAccept={() => {
        console.log("Cookies accepted");
      }}
      onDecline={() => {
        console.log("Cookies declined");
      }}
    >
      当サイトではより良いサービスを提供するため、Cookieを使用しています。詳細は
      <a href="/privacy" style={{ color: "#4e9fff" }}>こちら</a>
      をご覧ください。
    </CookieConsent>
を掲載。
*/

const App = () => (
  <Router>
    <ScrollTop />
    <SuurekiAppBar />
    <Routes>
      <Route path="/" element={<Home />} />                                       {/* トップページ */}
      <Route path="/search/:getdata" element={<Search />} />                      {/* 全文検索 */}
      <Route path="/about" element={<About />} />                                 {/* 崇暦とは */}
      <Route path="/contact" element={<Contact />} />                             {/* ご連絡・ご意見・ご要望 */}
      <Route path="/sitemap" element={<Sitemap />} />                             {/* サイトマップとは */}
      <Route path="/jump/:pageName" element={<Jump />} />                         {/*ショートカット */}
      <Route path="/calendar" element={<CalendarIndex />} />                      {/* カレンダー：日暦・月暦・年暦の検索 */}
      <Route path="/calendar/:date" element={<Calendar />} />                     {/* カレンダー：日暦・月暦・年暦 */}
      <Route path="/goodday" element={<GoodDayIndex />} />                        {/* カレンダー：十二直・二十八宿による吉凶 */}
      <Route path="/goodday/:getdata" element={<GoodDay />} />                    {/* カレンダー：十二直・二十八宿による吉凶 */}
      <Route path="/buddhafair" element={<BuddhaFairIndex />} />                  {/* カレンダー：御縁日 */}
      <Route path="/buddhafair/:getdata" element={<BuddhaFair />} />              {/* カレンダー：御縁日 */}
      <Route path="/aspect" element={<AspectIndex />} />                          {/* 座相 */}
      <Route path="/aspect/:date" element={<Aspect />} />                         {/* 座相 */}
      <Route path="/calendar_search" element={<CalendarSearchIndex />} />         {/* 全文検索 */}
      <Route path="/calendar_search/:pageName" element={<CalendarSearch />} />    {/* 全文検索 */}
      <Route path="/celebrity" element={<CelebrityIndex />} />                    {/* 著名人 */}
      <Route path="/celebrity/:pageName" element={<Celebrity />} />               {/* 著名人 */}
      <Route path="/celebrity_search" element={<CelebritySeacrhIndex />} />       {/* 著名人検索 */}
      <Route path="/celebrity_search/:pageName" element={<CelebritySeacrh />} />  {/* 著名人検索 */}
      <Route path="/celebrity_birthsearch/:pageName" element={<CelebrityBirthSearch />} />  {/* 著名人検索 */}
      <Route path="/birth" element={<BirthIndex />} />                             {/* 誕生日 */}
      <Route path="/birth/:date" element={<Birth />} />                            {/* 誕生日各ページ */}
      <Route path="/birth_compatible/:pageName" element={<BirthCompatible />} />   {/* 誕生日各ページ */}
      <Route path="/archives" element={<ArchivesIndex />} />                       {/* データベース */}
      <Route path="/archives/db_24sekki" element={<DB24sekki />} />                {/* DB:二十四節気 */}
      <Route path="/archives/db_etcday" element={<DBetcday />} />                  {/* DB:祝日ではない日と風習の一覧 */}
      <Route path="/archives/db_horiday" element={<DBhoridayindex />} />           {/* DB:祝日 */}
      <Route path="/archives/db_horiday/:date" element={<DBhoriday />} />          {/* カレンダー：日暦・月暦・年暦 */}
      <Route path="/archives/db_koyomihenkan" element={<DBkoyomihenkan />} />      {/* DB:年齢早見表 */}
      <Route path="/archives/db_countingage" element={<DBcountingAge />} />        {/* DB:年齢早見表 */}
      <Route path="/archives/db_adjpcalendar" element={<DBadjpcalendar />} />      {/* DB:西暦・和暦変換表 */}
      <Route path="/archives/db_moon" element={<DBmoonIndex />} />                 {/* DB:満月と新月の日 */}
      <Route path="/archives/db_moon/:date" element={<DBmoon />} />                {/* カレンダー：日暦・月暦・年暦 */}
      <Route path="/archives/db_rekigetsu" element={<DBrekigetsu />} />            {/* DB:暦月の干支と九星 */}
      <Route path="/archives/db_school_eg" element={<DBschooleg />} />             {/* DB:学校入学/卒業年表 */}
      <Route path="/archives/db_zodiacsigns" element={<DBzodiacsigns />} />        {/* DB:12星座と13星座と誕生日の早見表・星座表 */}
      <Route path="/privacy" element={<Privacy />} />                              {/* プライバシーポリシー */}
      <Route path="/update" element={<Update />} />                                {/* 更新情報 */}
      <Route path="/mailmagazine" element={<Mailmagazine />} />                    {/* メルマガ */}
      <Route path="/annotation" element={<AnnotationIndex />} />                   {/* 広告無での利用 */}
      <Route path="/annotation/12" element={<Annotation12 />} />                   {/* 十二直ページ */}
      <Route path="/annotation/28" element={<Annotation28 />} />                   {/* 二十八宿ページ */}
      <Route path="/annotation/24sekki" element={<Annotation24sekki />} />         {/* 二十四節気ページ */}
      <Route path="/annotation/72" element={<Annotation72 />} />                   {/* 二十四節気・七十二候ページ */}
      <Route path="/annotation/sz" element={<AnnotationSz />} />                   {/* 選日・雑節ページ */}
      <Route path="/annotation/eto" element={<AnnotationEto />} />                 {/* 選日・雑節ページ */}
      <Route path="/application" element={<Application />} />                      {/* 広告無での利用 */}
      <Route path="/navi" element={<Navi />} />                                    {/* 目的ナビ */}
      <Route path="/mypage" element={<Mypage />} />                                {/* マイページ */}
      <Route path="/mypage/birth-add/:getdata" element={<MypageBirthAdd />} />     {/* マイページ：生年月日の登録 */}
      <Route path="/mypage/birth-delete/:getdata" element={<MypageBirthDelete />} />                  {/* マイページ：生年月日の削除 */}
      <Route path="/test/:getdata" element={<Test />} />                                              {/* テストページ */}
      <Route path="/test2" element={<Test2 />} />                                              {/* テストページ */}
      <Route path="/wwwwww/Zzdgk5fk967fkdcdkuh" element={<Zzdgk5fk967fkdcdkuh />} />                  {/* 管理ページ */}
      <Route path="/wwwwww/Zzdgk5fk967fkdcdkuh/mail" element={<Zzdgk5fk967fkdcdkuhMail />} />         {/* 管理ページ */}
      <Route path="/wwwwww/Zzdgk5fk967fkdcdkuh/sitemap" element={<Zzdgk5fk967fkdcdkuhSitemap />} />   {/* 管理ページ */}
      <Route path="*" element={<NotFoundPage />} />                                                   {/* 404 not found page */}
    </Routes>
    <SuurekiBottomNavigation />
  </Router >
);

export default App;
