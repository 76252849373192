import React, { useEffect, useState, ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Button, Box, Breadcrumbs, Typography, Stack, IconButton, TextField, Alert, AlertTitle, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HomeIcon from '@mui/icons-material/Home';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { LineChart } from '@mui/x-charts';
import { biorhythm } from '../components/Birth_Biorhythm';
import bgImage from '../img/biorhythmBack.svg'
import bgImageYear from '../img/biorhythmBackYear.svg'
import SuurekiFooter from '../SuurekiFooter';
import {
    WeekName,
    Ninestar,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogReadUrl,
    birthDatReadUrl,
    presenceUrl,
} from '../SuurekiVar';
import SVGNinestarAngleImg from '../components/ninestarAngleImg';
import EncryptUserUid from '../components/EncryptUserUid';
import AdSenseBannerMobile from '../components/AdSenseBannerMobile'; // 320x100、ラージモバイルバナー をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // 記事内広告 をインポート
import { AuthProvider } from '../contexts/AuthContext';
import { GoogleSignIn } from '../components/GoogleSignIn';
import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

interface Koyomi {
    date: string;           /* 日付 */
    eto10_month: number;    /* 月干支（暦：10支）： */
    eto12_month: number;    /* 月干支（暦：12支） */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
    week: string;           /* 曜日 */
    eto10_day: number;      /* 日干支（10支） */
    eto12_day: number;      /* 日干支（12支） */
    kyuusei_y: number;      /* 年九星 */
    kyuusei_m: number;      /* 月九星 */
}

interface NinestarDaygb {
    date: string;           /* 日付 */
    kyuseiEveryday_total: string;
}

interface NinestarAngle {
    date: string;           /* 日付 */
    kyuseiAngle_ymd: string;
}

const AppContent = () => {
    const { user, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <div className="container mx-auto max-w-md mt-10">
            {user ? <UserProfile /> : <GoogleSignIn />}
        </div>
    );
};

const useUserEmail = () => {
    const { userInfo } = useAuth();
    return userInfo?.email || 'none';
};
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

const UserNameTxt = () => {
    const userEmail = useUserEmail();
    const userUid = useUserUid();

    if (userUid === 'none') {
        return null;
    }

    return (
        <div>
            <div>{userEmail}さんのマイページ</div>
            <div>userID: {userUid}</div>
        </div>
    );
};

interface AccessLogState {
    date: string;
    title: string;
    url: string;
}
interface BirthDatState {
    name_a: string;
    name_b: string;
    name_c: string;
    name_d: string;
    birth_a: string;
    birth_b: string;
    birth_c: string;
    birth_d: string;
}

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}

const pageTitle = `マイページ`
const pageTitleS = `マイページ`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

const Mypage: React.FC = React.memo(() => {
    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");

    const [error, setError] = useState("");
    const validateDate = (value: string) => {
        // const datePattern = /^(19[0-9]{2}|20[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
        // if (!datePattern.test(value)) {
        //     return "入力値は、入力可能範囲外。";
        // }
        const year = parseInt(value.substring(0, 4), 10);
        const month = parseInt(value.substring(4, 6), 10) - 1; // month is 0-indexed
        const day = parseInt(value.substring(6, 8), 10);
        const date = dayjs(new Date(year, month, day));
        if (!date.isValid() || date.format('YYYYMMDD') !== value) {
            return "存在しない日付です。";
        }
        return "";
    };

    // 登録した生年月日jsonの呼び出し //////////////////////////////////////////////////////////////////////
    /*
    { "name_a": "ok", "birth_a": "19640202" },
    { "name_b": "", "birth_b": "" },
    { "name_c": "", "birth_c": "" },
    { "name_d": "", "birth_d": "" }
    */

    const birthdata_json_url = birthDatReadUrl;
    const [birthdata_data, setDatabirthdatadataState] = useState<BirthDatState[]>([]);
    useEffect(() => {
        fetch(birthdata_json_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ url: pagePath, uid: encryptedUid }).toString(),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(birthdata_data => setDatabirthdatadataState(birthdata_data))
            .catch(error => console.error('Error:', error));
    }, [birthdata_json_url, encryptedUid]);

    let birthdatastateNameA: { [count: number]: string } = {};
    let birthdatastateNameB: { [count: number]: string } = {};
    let birthdatastateNameC: { [count: number]: string } = {};
    let birthdatastateNameD: { [count: number]: string } = {};
    let birthdatastateBirthA: { [count: number]: string } = {};
    let birthdatastateBirthB: { [count: number]: string } = {};
    let birthdatastateBirthC: { [count: number]: string } = {};
    let birthdatastateBirthD: { [count: number]: string } = {};

    let count = 0;
    birthdata_data.forEach(birthdata => {
        birthdatastateNameA[count] = birthdata.name_a;
        birthdatastateNameB[count] = birthdata.name_b;
        birthdatastateNameC[count] = birthdata.name_c;
        birthdatastateNameD[count] = birthdata.name_d;
        birthdatastateBirthA[count] = birthdata.birth_a;
        birthdatastateBirthB[count] = birthdata.birth_b;
        birthdatastateBirthC[count] = birthdata.birth_c;
        birthdatastateBirthD[count] = birthdata.birth_d;
    });

    // 今月と先月の合算アクセスログjsonの呼び出し //////////////////////////////////////////////////////////////////////
    const accesslog_json_url = pagelogReadUrl;
    const [accesslog_data, setDataaccesslogState] = useState<AccessLogState[]>([]);
    useEffect(() => {
        fetch(accesslog_json_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ url: pagePath, uid: encryptedUid }).toString(),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(accesslog_data => setDataaccesslogState(accesslog_data))
            .catch(error => console.error('Error:', error));
    }, [accesslog_json_url, encryptedUid]);

    let accesslogstateDate: { [count: number]: string } = {};
    let accesslogstateTitle: { [count: number]: string } = {};
    let accesslogstateUrl: { [count: number]: string } = {};
    count = 0;
    accesslog_data.forEach(accesslog => {
        accesslogstateDate[count] = accesslog.date;
        accesslogstateTitle[count] = accesslog.title;
        accesslogstateUrl[count] = accesslog.url;
        count++;
    });

    // //////////////////////////////////////////////////////////////////////

    const maxAccessLog = 30;    //表示件数の最大件数
    const maxAccessLogDay = 30;    //表示件数の最大日数
    const todayDayjs = dayjs();
    const today = new Date();
    const todayY = today.getFullYear().toString(); // 年
    const todayM = (today.getMonth() + 1).toString().padStart(2, '0'); // 月（0-11なので+1が必要）
    const todayD = today.getDate().toString().padStart(2, '0'); // 日
    const todayYMD = todayY + todayM + todayD;
    // const todayW = today.getDay();
    const agoYearYMD = Number(todayYMD) - 10000;


    const filteredAccessLogData = accesslog_data.filter(accesslog => {
        const accessDate = dayjs(accesslog.date);
        return todayDayjs.diff(accessDate, 'day') <= maxAccessLogDay;
    });

    let birthKJ = null;
    let birthKN: string;

    let birth_aY = '';
    let birth_aM = '';
    let birth_aD = '';
    let birth_aYMD = '';
    let birthTxtJp = '';
    let birthBiorhythm = "";


    if (birthdatastateBirthA[0]) {
        birth_aY = birthdatastateBirthA[0].substring(0, 4);
        birth_aM = birthdatastateBirthA[0].substring(4, 6);
        birth_aD = birthdatastateBirthA[0].substring(6, 8);
        birth_aYMD = birthdatastateBirthA[0];
        birthTxtJp = `${birth_aY}年${Number(birth_aM)}月${Number(birth_aD)}日生の`;
    }


    // //////////////////////////////////////////////////////////////////////


    // encryptedUidが存在すれば、ログイン状態、存在しなければ、未ログイン状態を変数化
    // true:ログイン状態
    // false:未ログイン状態

    // userUidが、「none」か挿入されているか、nullの場合、未ログイン状態を変数化
    const loginState = userUid === 'none' || userUid === null ? false : true;
    // const loginState = encryptedUid ? true : false;
    const birthState = birthdatastateBirthA[0] ? true : false;

    // console.log('loginState:', loginState);


    // 今日の暦jsonの呼び出し //////////////////////////////////////////////////////////////////////

    // const calendar_json_url = "https://db.suureki.net/_db_calendar/calendar_" + `${birthY}` + ".json";
    const calendar_json_url = `https://db.suureki.net/_db_calendar/calendar_${birth_aY}.json`;

    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    useEffect(() => {
        if (birth_aYMD) {
            fetch(calendar_json_url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(koyomi_data => setDataKoyomi(koyomi_data))
                .catch(error => console.error('Error:', error));
        }
    }, [calendar_json_url, birth_aYMD]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let weekMap: { [date: string]: number } = {};
    let eto10dayMap: { [date: string]: number } = {};
    let eto12dayMap: { [date: string]: number } = {};
    let eto10yearMap: { [date: string]: number } = {};
    let eto12yearMap: { [date: string]: number } = {};
    let kyuuseiyearMap: { [date: string]: number } = {};
    koyomi_data.forEach(koyomi => {
        weekMap[koyomi.date] = Number(koyomi.week);
        eto10dayMap[koyomi.date] = koyomi.eto10_day;
        eto12dayMap[koyomi.date] = koyomi.eto12_day;
        eto10yearMap[koyomi.date] = koyomi.eto10_year;
        eto12yearMap[koyomi.date] = koyomi.eto12_year;
        kyuuseiyearMap[koyomi.date] = koyomi.kyuusei_y;
    });

    birthKJ = Ninestar[kyuuseiyearMap[birth_aYMD]];
    birthKN = String(kyuuseiyearMap[birth_aYMD]);

    const kyuuseiNo = kyuuseiyearMap[birth_aYMD];

    let birthKJTxtJP = "";
    if (birthKJ !== undefined) {
        birthKJTxtJP = birthKJ + "人の";
    }

    // 九星の吉凶運勢の呼び出し //////////////////////////////////////////////////////////////////////

    // const ninestar_daygb_json_url = "https://db.suureki.net/_db_fortune_9star_daygb/KE_db_" + `${todayY}${todayM}` + ".json";
    const ninestar_daygb_json_url = `https://db.suureki.net/_db_fortune_9star_daygb/KE_db_${todayY}${todayM}.json`;

    const [ninestardaygb_data, setDataNinestarDaygb] = useState<NinestarDaygb[]>([]);
    useEffect(() => {
        fetch(ninestar_daygb_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(ninestardaygb_data => setDataNinestarDaygb(ninestardaygb_data))
            .catch(error => console.error('Error:', error));
    }, [ninestar_daygb_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let ninestarDaygbMap: { [date: string]: string } = {};
    ninestardaygb_data.forEach(ninestarB => {
        ninestarDaygbMap[ninestarB.date] = String(ninestarB.kyuseiEveryday_total);
    }, [birth_aY]);


    // 九星の吉方位の呼び出し //////////////////////////////////////////////////////////////////////

    let ninestar_angle_json_url = "";
    // kyuuseiyearMap[birthYMD] = 0;
    if (kyuuseiNo !== undefined) {
        // ninestar_angle_json_url = "https://db.suureki.net/_db_fortune_9star_angle/KL_db_" + `${todayY}${todayM}` + "_" + kyuuseiyearMap[birthYMD] + ".json";
        ninestar_angle_json_url = `https://db.suureki.net/_db_fortune_9star_angle/KL_db_${todayY}${todayM}_${kyuuseiyearMap[birth_aYMD]}.json`;
    } else {
        // ninestar_angle_json_url = "https://db.suureki.net/_db_fortune_9star_angle/KL_db_" + `${todayY}${todayM}` + "_0.json";
        ninestar_angle_json_url = `https://db.suureki.net/_db_fortune_9star_angle/KL_db_${todayY}${todayM}_0.json`;
    }
    const [ninestarangle_data, setDataNinestarAngle] = useState<NinestarAngle[]>([]);
    useEffect(() => {
        fetch(ninestar_angle_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(ninestarangle_data => setDataNinestarAngle(ninestarangle_data))
            .catch(error => console.error('Error:', error));
    }, [ninestar_angle_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let ninestarAngleMap: { [date: string]: string } = {};
    ninestarangle_data.forEach(ninestarA => {
        ninestarAngleMap[ninestarA.date] = String(ninestarA.kyuseiAngle_ymd);
    }, [birth_aY]);


    // //////////////////////////////////////////////////////////////////////


    // let birthNinestarAngleTitle01 = ""; // -7日
    // let birthNinestarAngleTitle02 = ""; // -6日
    // let birthNinestarAngleTitle03 = ""; // -5日
    // let birthNinestarAngleTitle04 = ""; // -4日
    // let birthNinestarAngleTitle05 = ""; // -3日
    let birthNinestarAngleTitle06 = ""; // -2日
    let birthNinestarAngleTitle07 = ""; // -1日
    let birthNinestarAngleTitle08 = ""; // 今日
    let birthNinestarAngleTitle09 = ""; // +1日
    let birthNinestarAngleTitle10 = ""; // +2日
    let birthNinestarAngleTitle11 = ""; // +3日
    let birthNinestarAngleTitle12 = ""; // +4日
    let birthNinestarAngleTitle13 = ""; // +5日
    let birthNinestarAngleTitle14 = ""; // +6日
    let birthNinestarAngleTitle15 = ""; // +7日

    // let birthNinestarAngleDATA01 = "";
    // let birthNinestarAngleDATA02 = "";
    // let birthNinestarAngleDATA03 = "";
    // let birthNinestarAngleDATA04 = "";
    // let birthNinestarAngleDATA05 = "";
    let birthNinestarAngleDATA06 = "";
    let birthNinestarAngleDATA07 = "";
    let birthNinestarAngleDATA08 = "";
    let birthNinestarAngleDATA09 = "";
    let birthNinestarAngleDATA10 = "";
    let birthNinestarAngleDATA11 = "";
    let birthNinestarAngleDATA12 = "";
    let birthNinestarAngleDATA13 = "";
    let birthNinestarAngleDATA14 = "";
    let birthNinestarAngleDATA15 = "";


    let birthAspect = "";
    let birthHuman = "";

    let birthHumanList: ReactNode[] = [];

    // バイオリズムのグラフ
    let biorhythmDataP = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let biorhythmDataS = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let biorhythmDataI = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    // 九星のグラフ
    let ninestarData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let ninestarDataA = [-3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3, -3];
    let ninestarDataB = [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3];

    let birthNinestarD01 = null; // -7日
    let birthNinestarD02 = null; // -6日
    let birthNinestarD03 = null; // -5日
    let birthNinestarD04 = null; // -4日
    let birthNinestarD05 = null; // -3日
    let birthNinestarD06 = null; // -2日
    let birthNinestarD07 = null; // -1日
    let birthNinestarD08 = null; // 今日
    let birthNinestarD09 = null; // +1日
    let birthNinestarD10 = null; // +2日
    let birthNinestarD11 = null; // +3日
    let birthNinestarD12 = null; // +4日
    let birthNinestarD13 = null; // +5日
    let birthNinestarD14 = null; // +6日
    let birthNinestarD15 = null; // +7日
    let birthNinestarD16 = null; // +8日
    let birthNinestarD17 = null; // +9日
    let birthNinestarD18 = null; // +10日
    let birthNinestarD19 = null; // +11日
    let birthNinestarD20 = null; // +12日
    let birthNinestarD21 = null; // +13日
    let birthNinestarD22 = null; // +14日

    // const biorhythmLabels = ['7日前', '-6', '-5', '-4', '-3', '-2', '-1', '今日', '+1', '+2', '+3', '+4', '+5', '+6', '7日後', '+8', '+9', '+10', '+11', '+12', '+13', '14日後',];
    const biorhythmLabels = Array.from({ length: 22 }, (_, i) => {
        const newDate = new Date(today);
        newDate.setDate(today.getDate() + i - 7); // 7日前からスタート

        // 月と日を取得
        // const month = newDate.getMonth() + 1; // JavaScriptの月は0から始まるため+1
        const date = newDate.getDate();

        return `${date}日`;
    });

    const biorhythmYearLabels = Array.from({ length: 11 }, (_, i) => {
        return `${i + Number(todayY) - 5}年`;
    });

    if (WeekName[weekMap[birth_aYMD]] !== undefined) {

        ///////////////////////////////////////////////////////////
        // バイオリズムの作成

        // 誕生日が、1年前より前ならOK
        if (agoYearYMD >= Number(birth_aYMD)) {
            const { p: biorhythmAnswerP, s: biorhythmAnswerS, i: biorhythmAnswerI } = biorhythm(String(birth_aY), birth_aM, birth_aD, todayY, todayM, todayD);
            biorhythmDataP = [
                biorhythmAnswerP.p01, biorhythmAnswerP.p02, biorhythmAnswerP.p03, biorhythmAnswerP.p04, biorhythmAnswerP.p05,
                biorhythmAnswerP.p06, biorhythmAnswerP.p07, biorhythmAnswerP.p08, biorhythmAnswerP.p09, biorhythmAnswerP.p10,
                biorhythmAnswerP.p11, biorhythmAnswerP.p12, biorhythmAnswerP.p13, biorhythmAnswerP.p14, biorhythmAnswerP.p15,
                biorhythmAnswerP.p16, biorhythmAnswerP.p17, biorhythmAnswerP.p18, biorhythmAnswerP.p19, biorhythmAnswerP.p20,
                biorhythmAnswerP.p21, biorhythmAnswerP.p22
            ];
            biorhythmDataS = [
                biorhythmAnswerS.s01, biorhythmAnswerS.s02, biorhythmAnswerS.s03, biorhythmAnswerS.s04, biorhythmAnswerS.s05,
                biorhythmAnswerS.s06, biorhythmAnswerS.s07, biorhythmAnswerS.s08, biorhythmAnswerS.s09, biorhythmAnswerS.s10,
                biorhythmAnswerS.s11, biorhythmAnswerS.s12, biorhythmAnswerS.s13, biorhythmAnswerS.s14, biorhythmAnswerS.s15,
                biorhythmAnswerS.s16, biorhythmAnswerS.s17, biorhythmAnswerS.s18, biorhythmAnswerS.s19, biorhythmAnswerS.s20,
                biorhythmAnswerS.s21, biorhythmAnswerS.s22
            ];
            biorhythmDataI = [
                biorhythmAnswerI.i01, biorhythmAnswerI.i02, biorhythmAnswerI.i03, biorhythmAnswerI.i04, biorhythmAnswerI.i05,
                biorhythmAnswerI.i06, biorhythmAnswerI.i07, biorhythmAnswerI.i08, biorhythmAnswerI.i09, biorhythmAnswerI.i10,
                biorhythmAnswerI.i11, biorhythmAnswerI.i12, biorhythmAnswerI.i13, biorhythmAnswerI.i14, biorhythmAnswerI.i15,
                biorhythmAnswerI.i16, biorhythmAnswerI.i17, biorhythmAnswerI.i18, biorhythmAnswerI.i19, biorhythmAnswerI.i20,
                biorhythmAnswerI.i21, biorhythmAnswerI.i22
            ];
            birthBiorhythm = "";
        } else {
            birthBiorhythm = "1年前より過去の誕生日のみ、表示できます。";
        }

        ///////////////////////////////////////////////////////////
        // ＊＊星人の吉凶運勢の作成

        const birthNinestarYMD: string[] = [];
        const birthNinestarYMDJ: string[] = [];
        const birthNinestarCurrentDate = new Date(); // 現在の日付を取得
        birthNinestarCurrentDate.setDate(birthNinestarCurrentDate.getDate() - 7); // 7日前に設定
        let tmpYMD;
        let tmpW;

        for (let i = 0; i < 22; i++) { // 7日前から14日後までの22日間
            const tmpYyear = birthNinestarCurrentDate.getFullYear();
            const tmpMonth = (birthNinestarCurrentDate.getMonth() + 1).toString().padStart(2, '0'); // 1桁の場合、0で埋める
            const tmpDay = birthNinestarCurrentDate.getDate().toString().padStart(2, '0'); // 1桁の場合、0で埋める
            birthNinestarYMD[i] = `${tmpYyear}${tmpMonth}${tmpDay}`; // ninestar配列に年月日を割り当て
            birthNinestarCurrentDate.setDate(birthNinestarCurrentDate.getDate() + 1); // 翌日に設定
            tmpYMD = new Date(tmpYyear, (Number(tmpMonth) - 1), Number(tmpDay));
            tmpW = tmpYMD.getDay();

            birthNinestarYMDJ[i] = `${tmpYyear}年${parseInt(tmpMonth, 10)}月${parseInt(tmpDay, 10)}日（` + WeekName[tmpW] + `）`;
        }

        // console.log(birthNinestarD01);

        /*
            "date": "19051001",
            "kyuseiEveryday_total": "321123323"
        */

        // console.log(kyuuseiyearMap[19750101]);
        birthNinestarD01 = (ninestarDaygbMap[birthNinestarYMD[0]] ? ninestarDaygbMap[birthNinestarYMD[0]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD02 = (ninestarDaygbMap[birthNinestarYMD[1]] ? ninestarDaygbMap[birthNinestarYMD[1]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD03 = (ninestarDaygbMap[birthNinestarYMD[2]] ? ninestarDaygbMap[birthNinestarYMD[2]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD04 = (ninestarDaygbMap[birthNinestarYMD[3]] ? ninestarDaygbMap[birthNinestarYMD[3]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD05 = (ninestarDaygbMap[birthNinestarYMD[4]] ? ninestarDaygbMap[birthNinestarYMD[4]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD06 = (ninestarDaygbMap[birthNinestarYMD[5]] ? ninestarDaygbMap[birthNinestarYMD[5]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD07 = (ninestarDaygbMap[birthNinestarYMD[6]] ? ninestarDaygbMap[birthNinestarYMD[6]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD08 = (ninestarDaygbMap[birthNinestarYMD[7]] ? ninestarDaygbMap[birthNinestarYMD[7]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD09 = (ninestarDaygbMap[birthNinestarYMD[8]] ? ninestarDaygbMap[birthNinestarYMD[8]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD10 = (ninestarDaygbMap[birthNinestarYMD[9]] ? ninestarDaygbMap[birthNinestarYMD[9]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD11 = (ninestarDaygbMap[birthNinestarYMD[10]] ? ninestarDaygbMap[birthNinestarYMD[10]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD12 = (ninestarDaygbMap[birthNinestarYMD[11]] ? ninestarDaygbMap[birthNinestarYMD[11]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD13 = (ninestarDaygbMap[birthNinestarYMD[12]] ? ninestarDaygbMap[birthNinestarYMD[12]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD14 = (ninestarDaygbMap[birthNinestarYMD[13]] ? ninestarDaygbMap[birthNinestarYMD[13]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD15 = (ninestarDaygbMap[birthNinestarYMD[14]] ? ninestarDaygbMap[birthNinestarYMD[14]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD16 = (ninestarDaygbMap[birthNinestarYMD[15]] ? ninestarDaygbMap[birthNinestarYMD[15]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD17 = (ninestarDaygbMap[birthNinestarYMD[16]] ? ninestarDaygbMap[birthNinestarYMD[16]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD18 = (ninestarDaygbMap[birthNinestarYMD[17]] ? ninestarDaygbMap[birthNinestarYMD[17]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD19 = (ninestarDaygbMap[birthNinestarYMD[18]] ? ninestarDaygbMap[birthNinestarYMD[18]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD20 = (ninestarDaygbMap[birthNinestarYMD[19]] ? ninestarDaygbMap[birthNinestarYMD[19]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD21 = (ninestarDaygbMap[birthNinestarYMD[20]] ? ninestarDaygbMap[birthNinestarYMD[20]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD22 = (ninestarDaygbMap[birthNinestarYMD[21]] ? ninestarDaygbMap[birthNinestarYMD[21]].substring(kyuuseiyearMap[birth_aYMD], kyuuseiyearMap[birth_aYMD] + 1) : '');
        birthNinestarD01 = birthNinestarD01.substring(0, 1);
        birthNinestarD02 = birthNinestarD02.substring(0, 1);
        birthNinestarD03 = birthNinestarD03.substring(0, 1);
        birthNinestarD04 = birthNinestarD04.substring(0, 1);
        birthNinestarD05 = birthNinestarD05.substring(0, 1);
        birthNinestarD06 = birthNinestarD06.substring(0, 1);
        birthNinestarD07 = birthNinestarD07.substring(0, 1);
        birthNinestarD08 = birthNinestarD08.substring(0, 1);
        birthNinestarD09 = birthNinestarD09.substring(0, 1);
        birthNinestarD10 = birthNinestarD10.substring(0, 1);
        birthNinestarD11 = birthNinestarD11.substring(0, 1);
        birthNinestarD12 = birthNinestarD12.substring(0, 1);
        birthNinestarD13 = birthNinestarD13.substring(0, 1);
        birthNinestarD14 = birthNinestarD14.substring(0, 1);
        birthNinestarD15 = birthNinestarD15.substring(0, 1);
        birthNinestarD16 = birthNinestarD16.substring(0, 1);
        birthNinestarD17 = birthNinestarD17.substring(0, 1);
        birthNinestarD18 = birthNinestarD18.substring(0, 1);
        birthNinestarD19 = birthNinestarD19.substring(0, 1);
        birthNinestarD20 = birthNinestarD20.substring(0, 1);
        birthNinestarD21 = birthNinestarD21.substring(0, 1);
        birthNinestarD22 = birthNinestarD22.substring(0, 1);

        // console.log(ninestarDaygbMap[birthNinestarYMD[0]] + " の、" + kyuuseiyearMap[birthYMD] + " は、" + birthNinestarD01);

        // 213313313 の、0 は、2
        // 213313313 の、1 は、13313313
        // 213313313 の、2 は、3313313
        // 213313313 の、3 は、313313
        // 213313313 の、4 は、13313
        // 213313313 の、5 は、3313
        // 213313313 の、6 は、313
        // 213313313 の、7 は、13
        // 213313313 の、8 は、3

        // 5 4 3 なら、2を引く、2 1 0 なら、3を引く
        if (Number(birthNinestarD01) >= 3) { birthNinestarD01 = Number(birthNinestarD01) - 2; } else { birthNinestarD01 = Number(birthNinestarD01) - 3 };
        if (Number(birthNinestarD02) >= 3) { birthNinestarD02 = Number(birthNinestarD02) - 2; } else { birthNinestarD02 = Number(birthNinestarD02) - 3 };
        if (Number(birthNinestarD03) >= 3) { birthNinestarD03 = Number(birthNinestarD03) - 2; } else { birthNinestarD03 = Number(birthNinestarD03) - 3 };
        if (Number(birthNinestarD04) >= 3) { birthNinestarD04 = Number(birthNinestarD04) - 2; } else { birthNinestarD04 = Number(birthNinestarD04) - 3 };
        if (Number(birthNinestarD05) >= 3) { birthNinestarD05 = Number(birthNinestarD05) - 2; } else { birthNinestarD05 = Number(birthNinestarD05) - 3 };
        if (Number(birthNinestarD06) >= 3) { birthNinestarD06 = Number(birthNinestarD06) - 2; } else { birthNinestarD06 = Number(birthNinestarD06) - 3 };
        if (Number(birthNinestarD07) >= 3) { birthNinestarD07 = Number(birthNinestarD07) - 2; } else { birthNinestarD07 = Number(birthNinestarD07) - 3 };
        if (Number(birthNinestarD08) >= 3) { birthNinestarD08 = Number(birthNinestarD08) - 2; } else { birthNinestarD08 = Number(birthNinestarD08) - 3 };
        if (Number(birthNinestarD09) >= 3) { birthNinestarD09 = Number(birthNinestarD09) - 2; } else { birthNinestarD09 = Number(birthNinestarD09) - 3 };
        if (Number(birthNinestarD10) >= 3) { birthNinestarD10 = Number(birthNinestarD10) - 2; } else { birthNinestarD10 = Number(birthNinestarD10) - 3 };
        if (Number(birthNinestarD11) >= 3) { birthNinestarD11 = Number(birthNinestarD11) - 2; } else { birthNinestarD11 = Number(birthNinestarD11) - 3 };
        if (Number(birthNinestarD12) >= 3) { birthNinestarD12 = Number(birthNinestarD12) - 2; } else { birthNinestarD12 = Number(birthNinestarD12) - 3 };
        if (Number(birthNinestarD13) >= 3) { birthNinestarD13 = Number(birthNinestarD13) - 2; } else { birthNinestarD13 = Number(birthNinestarD13) - 3 };
        if (Number(birthNinestarD14) >= 3) { birthNinestarD14 = Number(birthNinestarD14) - 2; } else { birthNinestarD14 = Number(birthNinestarD14) - 3 };
        if (Number(birthNinestarD15) >= 3) { birthNinestarD15 = Number(birthNinestarD15) - 2; } else { birthNinestarD15 = Number(birthNinestarD15) - 3 };
        if (Number(birthNinestarD16) >= 3) { birthNinestarD16 = Number(birthNinestarD16) - 2; } else { birthNinestarD16 = Number(birthNinestarD16) - 3 };
        if (Number(birthNinestarD17) >= 3) { birthNinestarD17 = Number(birthNinestarD17) - 2; } else { birthNinestarD17 = Number(birthNinestarD17) - 3 };
        if (Number(birthNinestarD18) >= 3) { birthNinestarD18 = Number(birthNinestarD18) - 2; } else { birthNinestarD18 = Number(birthNinestarD18) - 3 };
        if (Number(birthNinestarD19) >= 3) { birthNinestarD19 = Number(birthNinestarD19) - 2; } else { birthNinestarD19 = Number(birthNinestarD19) - 3 };
        if (Number(birthNinestarD20) >= 3) { birthNinestarD20 = Number(birthNinestarD20) - 2; } else { birthNinestarD20 = Number(birthNinestarD20) - 3 };
        if (Number(birthNinestarD21) >= 3) { birthNinestarD21 = Number(birthNinestarD21) - 2; } else { birthNinestarD21 = Number(birthNinestarD21) - 3 };
        if (Number(birthNinestarD22) >= 3) { birthNinestarD22 = Number(birthNinestarD22) - 2; } else { birthNinestarD22 = Number(birthNinestarD22) - 3 };

        ninestarData = [
            Number(birthNinestarD01), Number(birthNinestarD02), Number(birthNinestarD03), Number(birthNinestarD04), Number(birthNinestarD05),
            Number(birthNinestarD06), Number(birthNinestarD07), Number(birthNinestarD08), Number(birthNinestarD09), Number(birthNinestarD10),
            Number(birthNinestarD11), Number(birthNinestarD12), Number(birthNinestarD13), Number(birthNinestarD14), Number(birthNinestarD15),
            Number(birthNinestarD16), Number(birthNinestarD17), Number(birthNinestarD18), Number(birthNinestarD19), Number(birthNinestarD20),
            Number(birthNinestarD21), Number(birthNinestarD22)
        ];

        ///////////////////////////////////////////////////////////
        // ＊＊星人の吉方位の作成

        // birthNinestarAngleATitle = "" + todayY + "年" + parseInt(todayM, 10) + "月" + parseInt(todayD, 10) + "日（" + WeekName[todayW] + "）：<br />";

        // birthNinestarAngleTitle01 = birthNinestarYMDJ[0];   // 7日前の、吉方位カードのタイトル
        // birthNinestarAngleTitle02 = birthNinestarYMDJ[1];   // 6日前の、吉方位カードのタイトル
        // birthNinestarAngleTitle03 = birthNinestarYMDJ[2];   // 5日前の、吉方位カードのタイトル
        // birthNinestarAngleTitle04 = birthNinestarYMDJ[3];   // 4日前の、吉方位カードのタイトル
        // birthNinestarAngleTitle05 = birthNinestarYMDJ[4];   // 3日前の、吉方位カードのタイトル
        birthNinestarAngleTitle06 = birthNinestarYMDJ[5];   // 2日前の、吉方位カードのタイトル
        birthNinestarAngleTitle07 = birthNinestarYMDJ[6];   // 1日前の、吉方位カードのタイトル
        birthNinestarAngleTitle08 = birthNinestarYMDJ[7];   // 今日の、吉方位カードのタイトル
        birthNinestarAngleTitle09 = birthNinestarYMDJ[8];   // 1日後の、吉方位カードのタイトル
        birthNinestarAngleTitle10 = birthNinestarYMDJ[9];   // 2日後の、吉方位カードのタイトル
        birthNinestarAngleTitle11 = birthNinestarYMDJ[10];   // 3日後の、吉方位カードのタイトル
        birthNinestarAngleTitle12 = birthNinestarYMDJ[11];   // 4日後の、吉方位カードのタイトル
        birthNinestarAngleTitle13 = birthNinestarYMDJ[12];   // 5日後の、吉方位カードのタイトル
        birthNinestarAngleTitle14 = birthNinestarYMDJ[13];   // 6日後の、吉方位カードのタイトル
        birthNinestarAngleTitle15 = birthNinestarYMDJ[14];   // 7日後の、吉方位カードのタイトル

        // birthNinestarAngleDATA01 = ninestarAngleMap[birthNinestarYMD[0]];    // 7日前の、吉方位カードの24桁データ
        // birthNinestarAngleDATA02 = ninestarAngleMap[birthNinestarYMD[1]];    // 6日前の、吉方位カードの24桁データ
        // birthNinestarAngleDATA03 = ninestarAngleMap[birthNinestarYMD[2]];    // 5日前の、吉方位カードの24桁データ
        // birthNinestarAngleDATA04 = ninestarAngleMap[birthNinestarYMD[3]];    // 4日前の、吉方位カードの24桁データ
        // birthNinestarAngleDATA05 = ninestarAngleMap[birthNinestarYMD[4]];    // 3日前の、吉方位カードの24桁データ
        birthNinestarAngleDATA06 = ninestarAngleMap[birthNinestarYMD[5]];    // 2日前の、吉方位カードの24桁データ
        birthNinestarAngleDATA07 = ninestarAngleMap[birthNinestarYMD[6]];    // 1日前の、吉方位カードの24桁データ
        birthNinestarAngleDATA08 = ninestarAngleMap[birthNinestarYMD[7]];    // 今日の、吉方位カードの24桁データ
        birthNinestarAngleDATA09 = ninestarAngleMap[birthNinestarYMD[8]];    // 1日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA10 = ninestarAngleMap[birthNinestarYMD[9]];    // 2日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA11 = ninestarAngleMap[birthNinestarYMD[10]];    // 3日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA12 = ninestarAngleMap[birthNinestarYMD[11]];    // 4日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA13 = ninestarAngleMap[birthNinestarYMD[12]];    // 5日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA14 = ninestarAngleMap[birthNinestarYMD[13]];    // 6日後の、吉方位カードの24桁データ
        birthNinestarAngleDATA15 = ninestarAngleMap[birthNinestarYMD[14]];    // 7日後の、吉方位カードの24桁データ

    }

    const [openB, setOpenB] = useState(false);
    const handleClickOpenB = () => {
        setOpenB(true);
    };
    const handleCloseB = () => {
        setOpenB(false);
    };

    // true:ログイン状態
    // false:未ログイン状態

    count = 0;
    const mainContents = [
        <div key='maincontentskey'>
            {/* 生年月日の登録・変更・削除表示 ----------------------------------------------　*/}
            <Box className='content_subbox_txt'>
                {loginState && !birthState && (
                    <>
                        {/* loginState が、true:ログイン状態 */}
                        {/* birthState が、false:未登録状態 */}
                        <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                            <AlertTitle sx={{ fontSize: '0.9rem' }}>
                                生年月日を登録すると、このページにて、バイオリズム・日々の吉凶運勢・日々の吉方位が表示されます。<br />
                                登録した生年月日は、いつでも変更・削除が出来ます。
                            </AlertTitle>
                        </Alert>
                    </>
                )}
                {birthState ? (
                    <>
                        {/* birthState が、true:登録状態 */}
                        <hr style={{
                            margin: '2rem 0rem',
                            border: 'none', // 既存のボーダーを無効化
                            height: '1px',  // 線の太さを指定
                            backgroundColor: '#BBB' // 線の色を指定
                        }} />
                    </>
                ) : null}
                {loginState ? (
                    <>
                        {/* loginState が、true:ログイン状態 */}
                        <Grid container alignItems="center" spacing={2}>
                            <Grid>
                                <TextField
                                    id="birth-add"
                                    label="生年月日を入力"
                                    variant="outlined"
                                    type="text"
                                    value={searchValue}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value) && value.length <= 8) { // 最大入力文字数を8に制限
                                            setSearchValue(value);
                                            setError(validateDate(value));
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && searchValue && !error) {
                                            navigate(`/mypage/birth-add/${searchValue}-${encryptedUid}`);
                                        }
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#3277d5',
                                            },
                                        },
                                        backgroundColor: 'white',
                                        width: '10rem',
                                        border: '0px solid #000',
                                        borderRadius: 1
                                    }}
                                />
                            </Grid>
                            <Grid sx={{ backgroundColor: '#1976d2', borderRadius: '4px' }}>
                                <IconButton
                                    aria-label="search"
                                    onClick={() => {
                                        // SearchIconがクリックされたときに遷移
                                        if (searchValue) {
                                            navigate(`/mypage/birth-add/${searchValue}-${encryptedUid}`);
                                        }
                                    }}
                                >
                                    <span style={{ fontSize: '1rem', marginLeft: '0.5rem', marginRight: '0.5rem', color: 'white' }}>登録 / 変更</span>
                                </IconButton>
                            </Grid>
                            <Grid sx={{ backgroundColor: '#fff', borderRadius: '4px', border: '2px solid #d04a39' }}>
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        navigate(`/mypage/birth-delete/${encryptedUid}`);
                                    }}
                                >
                                    <span style={{ fontSize: '1rem', marginLeft: '0.5rem', marginRight: '0.5rem', color: '#d04a39' }}>削除</span>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <div style={{ fontSize: '0.9rem', marginTop: '0.5rem', color: 'green' }}>
                            8桁で入力、入力可能範囲：19010101~20991231
                        </div>
                        <div style={{ fontSize: '0.9rem', marginTop: '1.5rem', color: 'black' }}>
                            以下のページでは、{birthTxtJp}の様々な情報をご覧いただけます。
                        </div>
                        <Link component={RouterLink} underline="always" key="update" color="primary" to={`/birth/${birthdatastateBirthA[0]}`} onClick={handleClick}>
                            {birthTxtJp}情報・吉凶判断
                        </Link>
                        {error && (
                            <Typography color="green" variant="body2">
                                {error}
                            </Typography>
                        )}
                    </>
                ) : (
                    <>
                        {/* loginState が、false:未ログイン状態 */}
                        <div>マイページの利用の際、新規登録は必要ありません。<br />Googleアカウントでログインすると、サイト内の閲覧履歴等がご利用になれます。</div>
                    </>
                )}
            </Box>
            {/* 日々の吉凶運勢の表示 ----------------------------------------------　*/}
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{birthKJTxtJP}日々の吉凶運勢</h2>
                {birthState ? (
                    <>
                        {/* birthState が、true:登録状態 */}
                        <table style={{ width: '100%', tableLayout: 'fixed', marginTop: '-3rem' }}>
                            <tbody>
                                <tr>
                                    <td style={{ height: '420px' }}>
                                        <div style={{
                                            width: '100%',
                                            height: '320px',
                                            overflowY: 'auto',
                                            overflowX: 'auto'
                                        }}>
                                            <LineChart
                                                sx={{ backgroundImage: `url(${bgImage})`, touchAction: 'pan-x' }}
                                                width={700}
                                                height={300}
                                                series={[
                                                    { data: ninestarDataB, label: '吉・上限', color: '#a6d2e9' },
                                                    { data: ninestarDataA, label: '凶・下限', color: '#ecb3bc' },
                                                    { data: ninestarData, label: '日々の吉凶', color: '#3ab2b0' },
                                                ]}
                                                xAxis={[{ scaleType: 'point', data: biorhythmLabels }]}
                                            >
                                            </LineChart>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                ) : (
                    <>
                        {/* birthState が、false:未登録状態 */}
                        <div style={{ color: 'gray' }}>ログオフ状態か生年月日が未登録の為、表示不可。</div>
                    </>
                )}
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            {/* バイオリズムの表示 ----------------------------------------------　*/}
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{birthTxtJp}バイオリズム</h2>
                {birthState ? (
                    <>
                        {/* birthState が、true:登録状態 */}
                        <table style={{ width: '100%', tableLayout: 'fixed', marginTop: '-3rem' }}>
                            <tbody>
                                <tr>
                                    <td style={{ height: '420px' }}>
                                        <div style={{
                                            width: '100%',
                                            height: '320px',
                                            overflowY: 'auto',
                                            overflowX: 'auto'
                                        }}>
                                            <LineChart
                                                sx={{ backgroundImage: `url(${bgImage})`, touchAction: 'pan-x' }}
                                                width={700}
                                                height={300}
                                                series={[
                                                    { data: biorhythmDataI, label: '感情', color: '#4797ff' },
                                                    { data: biorhythmDataS, label: '知性', color: '#b103db' },
                                                    { data: biorhythmDataP, label: '身体', color: '#3ab2b0' },
                                                ]}
                                                xAxis={[{ scaleType: 'point', data: biorhythmLabels }]}
                                            >
                                            </LineChart>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                ) : (
                    <>
                        {/* birthState が、false:未登録状態 */}
                        <div style={{ color: 'gray' }}>ログオフ状態か生年月日が未登録の為、表示不可。</div>
                    </>
                )}
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            {/* 日々の吉方位の表示 ----------------------------------------------　*/}
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{birthKJTxtJP}日々の吉方位</h2>
                {birthState ? (
                    <>
                        {/* birthState が、true:登録状態 */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                    m: 1,
                                    width: 230,
                                    height: 250,
                                },
                            }}
                        >
                            <Paper elevation={5} sx={{ padding: '15px' }}>
                                <div><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA08} /></div>
                                <div style={{ color: 'green', fontSize: '1.0rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>{birthNinestarAngleTitle08}、今日</div>
                            </Paper>
                            <Paper elevation={5} sx={{ padding: '15px' }}>
                                <div><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA09} /></div>
                                <div style={{ color: 'green', fontSize: '1.0rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>{birthNinestarAngleTitle09}、明日</div>
                            </Paper>
                            <Paper elevation={5} sx={{ padding: '15px' }}>
                                <div><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA10} /></div>
                                <div style={{ color: 'green', fontSize: '1.0rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>{birthNinestarAngleTitle10}、明後日</div>
                            </Paper>
                        </Box>
                        <div style={{ marginTop: '1rem' }}>
                            <Button variant="contained" onClick={handleClickOpenB}>
                                2日前から7日後を見る
                            </Button>
                            <Dialog
                                open={openB}
                                onClose={handleCloseB}
                                aria-labelledby="alert-ninestar"
                                aria-describedby="alert-ninestar-description"
                                maxWidth="xl"
                            >
                                <DialogContent>
                                    <DialogTitle>
                                        {birthKJ}人の吉方位
                                    </DialogTitle>
                                    <DialogContentText id="alert-ninestar-description">
                                        <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA06} /></span><br />
                                        <span style={{ color: 'green' }}>{birthNinestarAngleTitle06}（2日前）</span><br /><br />
                                        <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA07} /></span><br />
                                        <span style={{ color: 'green' }}>{birthNinestarAngleTitle07}（1日前）</span><br /><br />
                                        <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA08} /></span><br />
                                        <span style={{ color: 'green' }}>{birthNinestarAngleTitle08}（今日）</span><br /><br />
                                        <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA09} /></span><br />
                                        <span style={{ color: 'green' }}>{birthNinestarAngleTitle09}（1日後）</span><br /><br />
                                        <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA10} /></span><br />
                                        <span style={{ color: 'green' }}>{birthNinestarAngleTitle10}（2日後）</span><br /><br />
                                        <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA11} /></span><br />
                                        <span style={{ color: 'green' }}>{birthNinestarAngleTitle11}（3日後）</span><br /><br />
                                        <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA12} /></span><br />
                                        <span style={{ color: 'green' }}>{birthNinestarAngleTitle12}（4日後）</span><br /><br />
                                        <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA13} /></span><br />
                                        <span style={{ color: 'green' }}>{birthNinestarAngleTitle13}（5日後）</span><br /><br />
                                        <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA14} /></span><br />
                                        <span style={{ color: 'green' }}>{birthNinestarAngleTitle14}（6日後）</span><br /><br />
                                        <span style={{ borderTop: '2px solid #ddd', paddingTop: '1rem' }}><SVGNinestarAngleImg ninestarAngleDATA={birthNinestarAngleDATA15} /></span><br />
                                        <span style={{ color: 'green' }}>{birthNinestarAngleTitle15}（7日後）</span><br /><br />
                                    </DialogContentText >
                                </DialogContent >
                                <DialogActions>
                                    <Button onClick={handleCloseB}>閉じる</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </>
                ) : (
                    <>
                        {/* birthState が、false:未登録状態 */}
                        <div style={{ color: 'gray' }}>ログオフ状態か生年月日が未登録の為、表示不可。</div>
                    </>
                )}
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBannerMobile />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>閲覧履歴</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                    <AlertTitle sx={{ fontSize: '0.9rem' }}>最大表示日数：{maxAccessLogDay}日、最大表示件数：{maxAccessLog}件</AlertTitle>
                </Alert>
                {loginState ? (
                    <ul>
                        {filteredAccessLogData.map((accesslog, index, array) => {
                            count++;
                            // accesslog.titleが、続けて同じ内容の場合、2回目以降を非表示。
                            if (index > 0 && accesslog.title === array[index - 1].title) {
                                count--;
                                return null;
                            }
                            if (count <= maxAccessLog) {
                                return (
                                    <Box sx={{ letterSpacing: '0.1em' }} key={index}>
                                        <li className='inside-list-style-decimal' style={{ color: 'black' }}>
                                            <Link component={RouterLink} underline="always" key="update" color="primary" to={`/${accesslog.url}`} onClick={handleClick}>
                                                {accesslog.title}
                                            </Link>
                                            <span style={{ color: 'gray', fontSize: '0.8rem', letterSpacing: '0.05em' }}>（{accesslog.date}）</span>
                                        </li>
                                    </Box>
                                )
                            }
                            return null;
                        })}
                    </ul>
                ) : (
                    <div style={{ color: 'gray' }}>ログオフ状態の為、表示出来ません。</div>
                )}
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    /*
                    <h2 className='content_subtitle'>ログインボタン見本</h2>
                <div>
                    新規登録は必要ありません
                </div>
                <div>
                    メアドにログインが行われましたメール。
                </div>
                <button
                    className='inside-list-style'
                    style={{ backgroundColor: '#d04a39', color: 'white', padding: '5px 25px 5px 20px', margin: '1rem', height: '40px', width: '210px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', border: 'none', cursor: 'pointer' }}
                >
                    <GoogleIcon style={{ marginRight: '0.5rem' }} />
                    Googleでログイン
                </button>
                <button
                    className='inside-list-style'
                    style={{ backgroundColor: 'black', color: 'white', padding: '5px 25px 5px 20px', margin: '1rem', height: '40px', width: '210px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', border: 'none', cursor: 'pointer' }}
                >
                    <AppleIcon style={{ marginRight: '0.5rem' }} />
                    AppleIDでサインイン
                </button>
    */

    const pageKey = "Mypage";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;


    // ユーザー存在チェックの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (presenceUrl && encryptedUid) {

            fetch(presenceUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [presenceUrl, encryptedUid]);


    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,
                "@id": metaUrl,
                "url": metaUrl,
                "isPartOf": { "@id": siteUrlHead },
                "name": pageTitle,
                "datePublished": datePublished,
                "dateModified": dateModified[pageKey],
                "description": metaDescription,
                "image": publisherLogoUrl,
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} /><br />

                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <AppContent />
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});

const App = () => (
    <AuthProvider>
        <Mypage />
    </AuthProvider>
);

export default App;