import React, { useEffect } from 'react';

declare global {
    interface Window {
        adsbygoogle: any;
    }
}

// <!-- 320x100、ラージモバイルバナー -->

function AdSenseBannerMobile() {
    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
            console.error("AdSense 広告の読み込みエラー:", error);
        }
    }, []);

    return (
        <div style={{ width: '100%' }}> {/* width を 100% に変更 */}
            <ins
                className="adsbygoogle"
                style={{ display: 'inline-block', width: '100%', height: '100px', border: '1px solid gray' }}
                data-ad-client="ca-pub-2162909351269073"
                data-ad-slot="5899994103"
            ></ins>
        </div>
    );
}

export default AdSenseBannerMobile;
