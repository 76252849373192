// src/pages/Zzdgk5fk967fkdcdkuh.tsx
import * as React from 'react';
// import React, { useEffect, useState } from 'react';
// import { AlertCircle } from 'lucide-react';
// import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Alert } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteRobots,
} from '../SuurekiVar';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `管理ページ！`
let pageTitleS = `管理ページ！` // ページトップのナビゲーションリンク

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton sx={{
            margin: '0px 0px 1px 0px',
            padding: '0px'
        }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


// const Zzdgk5fk967fkdcdkuh: React.FC = () => {
const Zzdgk5fk967fkdcdkuh: React.FC = React.memo(() => {


    const today = new Date();
    // const dateY = today.getFullYear();
    const dateM = (today.getMonth() + 1).toString().padStart(2, '0');
    const dateD = today.getDate().toString().padStart(2, '0');
    // const date = dateY + dateM + dateD;
    let moreDay = '';

    if (Number(dateD) === 7 || Number(dateD) === 17 || Number(dateD) === 27) {
        moreDay = '6日以内に配信手続き';
    } else if (Number(dateD) === 8 || Number(dateD) === 18 || Number(dateD) === 28) {
        moreDay = '5日以内に配信手続き';
    } else if (Number(dateD) === 9 || Number(dateD) === 19 || Number(dateD) === 29) {
        moreDay = '4日以内に配信手続き';
    } else if (Number(dateD) === 10 || Number(dateD) === 20 || Number(dateD) === 30) {
        moreDay = '3日以内に配信手続き';
    } else if (Number(dateD) === 1 || Number(dateD) === 11 || Number(dateD) === 21 || Number(dateD) === 31) {
        moreDay = '2日以内に配信手続き';
    } else if (Number(dateD) === 2 || Number(dateD) === 12 || Number(dateD) === 22) {
        moreDay = '1日以内に配信手続き';
    } else if (Number(dateD) === 3 || Number(dateD) === 13 || Number(dateD) === 23) {
        moreDay = '本日中に配信手続き';
    }

    const mainContents = [
        <div key='maincontentskey'>

            <Box className='content_subbox_txt'>

                {/* ------------------------------------------------------------------------------------- */}
                {/* 著名人リストの修正・更新方法 */}
                <h2 className='content_subtitle' style={{ color: 'red' }}>メルマガ配信</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem', paddingTop: '1.0rem', paddingBottom: '1.0rem' }}>
                    <div>
                        <div>
                            <a href="https://www.mag2.com/" target="_blank" rel="noopener noreferrer">メルマガ配信：https://www.mag2.com/</a>（新しいタブ）
                        </div>
                        <div style={{ marginTop: '1.0rem' }}>
                            <Link
                                component={RouterLink}
                                underline="always"
                                key="mail-genkou"
                                color="primary"
                                to="/wwwwww/Zzdgk5fk967fkdcdkuh/mail"
                                onClick={handleClick}
                            >
                                メルマガ原稿
                            </Link>
                        </div>
                        <div style={{ marginTop: '1.0rem' }}>
                            和暦「令和」が変わったら、Zzdgk5fk967fkdcdkuhMail.tsx を直接編集。<br />
                            reiwa = dateY - 2018;<br />
                            reiwaJ = '令和' + reiwa;
                        </div>
                    </div>
                </Alert>
                <div style={{ color: 'red', fontSize: '2rem' }}>{Number(dateM)}月{Number(dateD)}日、{moreDay}</div>
                {/* ------------------------------------------------------------------------------------- */}
                {/* 著名人リストの修正・更新方法 */}
                <h2 className='content_subtitle' style={{ color: 'red' }}>著名人リストの修正・更新方法</h2>
                <div>
                    <div style={{ color: 'black' }}>崇暦への ご連絡・ご意見・ご要望</div>
                    <div style={{ color: 'black' }}>適宜確認</div>
                </div>
                <div>
                    <a href="https://docs.google.com/forms/d/1BXAKDMPCrWAvygD3a-gWpgXq7VEpdShWtDVVbeihGIY/edit#responses" target="_blank" rel="noopener noreferrer">Googleフォーム</a>（新しいタブ）
                </div>

                {/* ------------------------------------------------------------------------------------- */}
                {/* 祭日の更新方法 */}
                <h2 className='content_subtitle' style={{ color: 'red' }}>祭日の更新方法</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1.5rem', paddingTop: '1.0rem', paddingBottom: '1.0rem' }}>
                    <div>
                        <a href="https://www8.cao.go.jp/chosei/shukujitsu/gaiyou.html" target="_blank" rel="noopener noreferrer">内閣府＞「国民の祝日」について</a>（新しいタブ）
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <Link
                            component={RouterLink}
                            underline="always"
                            key="mail-genkou"
                            color="primary"
                            to="/archives/db_horiday"
                            onClick={handleClick}
                        >
                            祝日/祭日の一覧
                        </Link>
                    </div>
                </Alert>
                <div style={{ color: 'red', fontSize: '1.2rem', marginBottom: '1rem' }}>・毎年2月に、内閣府の情報を転載</div>
                <div>
                    <div style={{ color: 'black' }}>・祝日/祭日の一覧を、手動編集</div>
                    <div style={{ color: 'black' }}>/_suureki2024_json/_db_horiday/horiday_all.json</div>
                </div>

                {/* ------------------------------------------------------------------------------------- */}
                {/* 著名人リストの修正・更新方法 */}
                <h2 className='content_subtitle' style={{ color: 'red' }}>著名人リストの修正・更新方法</h2>
                <div>
                    <div style={{ color: 'black' }}>・SQLiteの編集</div>
                    <div style={{ color: 'black' }}>・FTPにてアップロード</div>
                    <div style={{ color: 'black' }}>/_suureki2024_json/_db_celebrity/suureki2024.db</div>
                </div>
                {/* ------------------------------------------------------------------------------------- */}
                {/* 著名人リストの修正・更新方法 */}
                <h2 className='content_subtitle' style={{ color: 'red' }}>今月と来月の祝日ではない日と風習</h2>
                <div style={{ color: 'red', fontSize: '1.2rem', marginBottom: '1rem' }}>・2030年度末まで、編集済み</div>
                <div>
                    <div style={{ color: 'black' }}>・etcday_allの手動編集</div>
                    <div style={{ color: 'black' }}>・FTPにてアップロード</div>
                    <div style={{ color: 'black' }}>/_suureki2024_json/etcday_all.json</div>
                </div>
                {/* ------------------------------------------------------------------------------------- */}
                {/* 著名人リストの修正・更新方法 */}
                <h2 className='content_subtitle' style={{ color: 'red' }}>更新/Update、ビルドを行った場合</h2>
                <div>
                    <div style={{ color: 'black' }}>・package.json の編集</div>
                    <div style={{ color: 'black' }}>・package.json の3行目にある「version": "1.0.0"」のバージョンを書き換える！！</div>
                    <div style={{ color: 'black' }}>・package.json の"__comments" に、バージョン情報とアップデート内容を記録する！！</div>
                </div>
                {/* ------------------------------------------------------------------------------------- */}
                {/* サイト内検索ログ */}
                <h2 className='content_subtitle' style={{ color: 'red' }}>サイト内検索ログ</h2>
                <div>
                    <div style={{ color: 'black' }}>https://useruser.suureki.net/_search_log/log/YYYY/search_YYYYMMDD.txt</div>
                    <div style={{ color: 'black' }}>https://useruser.suureki.net/_search_log/log/2024/search_20241101.txt</div>
                </div>
                {/* ------------------------------------------------------------------------------------- */}
                {/* サイト内検索ログ */}
                <h2 className='content_subtitle' style={{ color: 'red' }}>サイトマップ生成</h2>
                <div style={{ marginTop: '1rem' }}>
                    <Link
                        component={RouterLink}
                        underline="always"
                        key="mail-genkou"
                        color="primary"
                        to="/wwwwww/Zzdgk5fk967fkdcdkuh/sitemap"
                        onClick={handleClick}
                    >
                        サイトマップ生成、一覧表示
                    </Link>
                </div>
            </Box>
        </div>
    ];

    // const pageKey = "Zzdgk5fk967fkdcdkuh";
    // const pagePath = "Zzdgk5fk967fkdcdkuh/mail";

    const metaTitle = pageTitle + siteName;

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <div style={{ flex: 1 }}>

                    {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                    <HelmetProvider>
                        <Helmet>
                            <title>{metaTitle}</title>
                            <meta name="robots" content={siteRobots['NG']} />
                        </Helmet>
                    </HelmetProvider>

                    {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                    <div className='topspace_height'></div>
                    <h1 className='page_title'>
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </h1>

                    {/* ***** メインコンテンツ ******************************************************************* */}

                    <div className='main_space'>
                        <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{pageTitle}</span>
                        </h1>
                        <div>{mainContents}</div>

                    </div>
                </div>

                {/* ***** フッター ******************************************************************* */}
                <SuurekiFooter />
            </div>
        </>
    );
});
//};

export default Zzdgk5fk967fkdcdkuh;