// src/SuurekiBottomNavigation.tsx

import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';    // メニューアイコン
import GridViewIcon from '@mui/icons-material/GridView';            // 四角4つのグリッドアイコン
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';  // カレンダーアイコン
import PermIdentityIcon from '@mui/icons-material/PermIdentity';    // 人形アイコン
import StorageIcon from '@mui/icons-material/Storage';              // ストレージアイコン
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';    // ビックリマークアイコン
import SearchIcon from '@mui/icons-material/Search';                // 検索アイコン
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import NavigationIcon from '@mui/icons-material/Navigation';

// import MailOutlineIcon from '@mui/icons-material/MailOutline';   // メールアイコン
// import Brightness3Icon from '@mui/icons-material/Brightness3';   // 月のアイコン
// import GradeIcon from '@mui/icons-material/Grade';               // 星型アイコン
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';  // 

import { Typography, Box } from '@mui/material';
// import Button from '@mui/material/Button';

const today = new Date();
const year = today.getFullYear();
const month = ("0" + (today.getMonth() + 1)).slice(-2);     // getMonthは0から11の値を返すため、+1する。また、1桁の月は0を先頭に追加。
// const months = today.getMonth() + 1;
const day = ("0" + today.getDate()).slice(-2);              // 1桁の日は0を先頭に追加
// const days = today.getDate();


/* menuアイコンを選択したとき出るmenuの、各アイコンの色とサイズ */
const styleIconButton: React.CSSProperties = {
    width: '70px',
    height: '70px',
    color: 'white',
    borderRadius: '0',
};


const SuurekiBottomNavigation = () => {

    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const timerID = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return function cleanup() {
            clearInterval(timerID);
        };
    }, []);

    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${year}-${month}-${day}　${hours}:${minutes}:${seconds}`;
    };





    /* menuアイコンと選択したとき出るmenuの、下端からの高さ */
    let styleIconButtonBottom = '120px';
    let styleIconButtonBottomTmpA = parseInt(styleIconButtonBottom, 10);    // '70px' を 70 に変換
    let styleIconButtonBottomTmpB = styleIconButtonBottomTmpA + 70;         // 70 に 100 を加算
    let styleIconButtonBottomP = styleIconButtonBottomTmpB + 'px';          // 170 を '170px' に変換

    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    return (
        <>

            {/* ***** ページトップまで、スクロールするボタン ************************* */}
            <IconButton
                aria-label="ページトップへ戻る" // ユーザー補助機能名を追加
                onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                sx={{
                    position: 'fixed',
                    bottom: styleIconButtonBottomP,
                    right: '0px',
                    color: 'white',
                    width: '50px',
                    height: '55px',
                    backgroundColor: '#3277d5',
                    borderRadius: '0',
                    "&:hover": {
                        backgroundColor: '#3277d5',
                    },
                }}
            >
                <KeyboardDoubleArrowUpIcon />
            </IconButton>

            {/* ***** メニューアイコン ************************* */}
            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: styleIconButtonBottom,
                    right: '0px',
                    color: 'white',
                    width: '50px',
                    height: '55px',
                    backgroundColor: '#3277d5',
                    borderRadius: '0',
                    "&:hover": {
                        backgroundColor: '#3277d5',
                    },
                }}
                onClick={handleMenuOpen}
            >
                <Box>
                    <GridViewIcon />
                    <Box sx={{ mt: -1.8 }}>
                        <Typography variant="caption">Menu</Typography>
                    </Box>
                </Box>
            </IconButton>

            {/* ***** メニューアイコンをクリックしたときの動作 ************************* */}
            <Dialog
                open={menuOpen}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        margin: `auto 70px ${styleIconButtonBottom} auto`,
                        maxWidth: 'xs',
                        maxHeight: 'xs',
                        backgroundColor: '#3277d5',
                        color: 'white'
                    },
                }}
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    position: 'relative',
                    '&::after': { // 疑似要素を使用
                        marginBottom: '10px',
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: '30%',
                        transform: 'translateX(-25%)',
                        width: '80%', // 下線の長さを指定
                        height: '1px',
                        backgroundColor: '#aaa', // 下線の色を指定
                    }
                }}
                >
                    <span style={{ fontSize: '1rem', letterSpacing: '0.1rem', color: 'yellow' }}>{formatDate(currentDate)}</span>
                </DialogTitle>
                <DialogContent>
                    <>
                        <div>
                            {/* Link-icon：データベース */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to="/navi"
                                style={styleIconButton}
                            >
                                <Box>
                                    <NavigationIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>目的ナビ</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                            {/* Link-icon：サイトマップ */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to="/sitemap"
                                style={styleIconButton}
                            >
                                <Box>
                                    <MenuOutlinedIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>Site Map</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                            {/* Link-icon：全て */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to="/mypage"
                                style={styleIconButton}
                            >
                                <Box>
                                    <AccountCircleIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.65rem' }}>マイページ</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                        </div>
                        <div>
                            {/* Link-icon：検索 */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to="/calendar_search"
                                style={styleIconButton}
                            >
                                <Box>
                                    <SearchIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>暦の検索</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                            {/* Link-icon：ホーム */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to="/celebrity"
                                style={styleIconButton}
                            >
                                <Box>
                                    <PermIdentityIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>著名人</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                            {/* Link-icon：誕生日 */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to="/birth"
                                style={styleIconButton}
                            >
                                <Box>
                                    <PermIdentityIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>誕生日</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                        </div>
                        <div>
                            {/* Link-icon：日暦 */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to={`/calendar/${year}${month}${day}`}
                                style={styleIconButton}
                            >
                                <Box>
                                    <CalendarMonthIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>今日の暦</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                            {/* Link-icon：ホーム */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to="/"
                                style={styleIconButton}
                            >
                                <Box sx={{ color: 'lightpink' }}>
                                    <HomeIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>ホーム</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                            {/* Link-icon：年暦 */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to={`/calendar/${year}`}
                                style={styleIconButton}
                            >
                                <Box>
                                    <CalendarMonthIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>今年の暦</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                        </div>
                        <div>
                            {/* Link-icon：吉凶暦 */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to={`/goodday/${year}${month}`}
                                style={styleIconButton}
                            >
                                <Box>
                                    <CalendarMonthIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>吉凶の暦</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                            {/* Link-icon：暦注暦 */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to={`/calendar/${year}${month}`}
                                style={styleIconButton}
                            >
                                <Box>
                                    <CalendarMonthIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>暦注の暦</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                            {/* Link-icon：ご縁日暦 */}
                            <IconButton
                                onClick={handleMenuClose}
                                component={Link}
                                to={`/buddhafair/${year}${month}`}
                                style={styleIconButton}
                            >
                                <Box>
                                    <CalendarMonthIcon />
                                    <Box sx={{ mt: -1.8 }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>縁日の暦</Typography>
                                    </Box>
                                </Box>
                            </IconButton>
                        </div>
                    </>
                </DialogContent>
            </Dialog >
        </>
    );
}

export default SuurekiBottomNavigation;