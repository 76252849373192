import React from 'react';
import { useForm } from 'react-hook-form';

const ContactForm = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = async (data: any) => {
        try {
            // Send the data to your backend API endpoint
            const response = await fetch('/api/send-email', { // Replace '/api/send-email' with your actual endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Email sent successfully, reset the form
            reset();
            alert('Your message has been sent!');
        } catch (error) {
            console.error('Error sending email:', error);
            alert('There was an error sending your message. Please try again later.');
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="name">Name:</label>
            <input type="text" {...register("name", { required: true })} />
            {errors.name && <span>Name is required</span>}

            <label htmlFor="email">Email:</label>
            <input type="email" {...register("email", { required: true, pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/ })} />
            {errors.email && <span>Please enter a valid email</span>}

            <label htmlFor="message">Message:</label>
            <textarea {...register("message", { required: true })} />
            {errors.message && <span>Message is required</span>}

            <button type="submit">Send</button>
        </form>
    );
};

export default ContactForm;
